<template>
  <app-image
      v-if="showImage"
      :alt="itemData?.data?.title + ' Screenshot'"
      class="screenshot"
      :class="type"
      :lazy-src="imageSrc"
      :height="height"
      :no-aspect-ratio="true"
  />
</template>


<script>
export default {
  name: "ImageWithDirection",
  props: {
    src: {
      type: String
    },
    itemData: {
      type: Object,
    },
    index: {},
    height: {
      type: Number,
      default: 224
    }
  },
  data() {
    return {
      type: '',
      imageSrc: '',
      showImage: false,
    }
  },
  async mounted() {
    await (async () => {
      const img = new Image();
      img.src = this.src;
      await img.decode();
      this.type = img.width > img.height ? 'horizontal' : 'vertical';
      this.imageSrc = this.src;
      this.showImage = true;
    })();
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>