<template>
  <div class="pseudo-textarea">
    <div class="pseudo-textarea-content custom-chips-block">
      <template v-if="noDeleteOption">
        <custom-chips v-for="(chip, idx) in chipsArray"
                      :is-frozen="chip.frozen"
                      :is-selected="true"
                      :selectable="true"
                      :show-tooltip="false"
                      @chip-deleted="deleteChips(chip)"
                      :key="idx">{{ chip.name }}
        </custom-chips>
      </template>
      <template v-else>
        <custom-chips @chip-deleted="deleteChips(chip)"
                      v-for="(chip, idx) in chipsArray"
                      :key="idx">{{ chip.name }}
        </custom-chips>
      </template>

      <input type="text"
             ref="chipInput"
             @keyup="keyPressHandler"
             @paste.prevent="pasteHandler"
             v-model="chipsInput"
             autofocus
             placeholder="Separate keywords with a comma">
      <div v-if="hasError"
           class="error-msg">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
import CustomChips from "@/components/Forms/CustomChips";

export default {
  name: "CustomTextareaWithChips",
  components: {
    'custom-chips': CustomChips
  },
  emits: ['chip-deleted', 'chip-added', 'input-changed'],
  props: {
    chipsArray: {
      type: Array
    },
    sourceArray: {
      type: Array
    },
    noDeleteOption: {
      type: Boolean
    }
  },
  data() {
    return {
      maxLength: 255,
      chipsInput: '',
      hasError: false,
      error: '',
    }
  },
  mounted() {
    this.$refs.chipInput.focus();
  },
  methods: {
    deleteChips(chip) {
      if (this.chipsArray) {
        this.$emit('chip-deleted', chip);
      }
    },
    addChips(value) {
      this.$emit('chip-added', {name: value, selected: true});
    },
    keyPressHandler(e) {
      let value = e.target.value.replace(',', '');
      if (e.key === 'Enter' || e.key === ',') {
        value = value.trim();

        if (value.length === 0) {
          return;
        }

        if (this.validate(value)) {
          this.addChips(value);
          this.chipsInput = '';
        }
      } else {
        if (this.validateLength(value)) {
          this.$emit('input-changed', value);
        }
      }
    },
    validateLength(value) {
      if (value.length > this.maxLength) {
        this.hasError = true;
        this.error = 'The keyword may not be greater than 255 characters.';
        return false;
      }

      this.hasError = false;
      this.error = '';

      return true;
    },
    validate(value) {
      if (!this.validateLength(value)) {
        return false;
      }

      const notUniqueChips = this.chipsArray.find(item => item?.name?.trim() === value);
      const notUniqueSource = this.sourceArray.find(item => item?.name?.trim() === value);
      if (notUniqueChips || notUniqueSource) {
        this.hasError = true;
        this.error = 'This keyword has already been added!';
        return false;
      }

      this.hasError = false;
      this.error = '';

      return true;
    },
    pasteHandler(e) {
      let paste = (e.clipboardData || window.clipboardData).getData('text');
      let splittedLineBreakText = paste.split(/\r?\n/).map(item => item.trim());
      if (splittedLineBreakText?.length === 0) {
        return;
      }

      let splittedByComma = [];
      splittedLineBreakText.forEach(item => {
        splittedByComma = [...splittedByComma, ...item.split(',')];
      });

      let splitted = [];
      splittedByComma.forEach(item => {
        splitted = [...splitted, ...item.split(/\t/)];
      });

      splitted = splitted.map(item => item.trim());
      splitted = splitted.filter(item => item !== '');

      let withError = splitted.filter(item => !this.validateLength(item));
      if (withError.length === 0) {
        splitted.forEach(newItem => {
          const notUniqueChips = this.chipsArray.find(chipItem => chipItem?.name?.trim() === newItem);
          if (!notUniqueChips) {
            this.addChips(newItem);
          }
        });

        this.chipsInput = '';
      } else {
        this.chipsInput = paste;
      }
    }
  },
}
</script>

<style scoped lang="scss" src="./styles.scss"></style>