<template>
  <div class="installs-chart"
       v-if="installsDataLoaded && !hideInstalls && JSON.stringify(appInstallsRevenue?.worldwide_chart_data) !== '{}'">
    <div class="tab-component-title" id="ww-installs-chart">
      Worldwide Installs (Google Play data)
    </div>

    <modal-app-metrics-new :is-metrics-data-loaded="true"
                           classes="new-metrics-wrap">
      <template v-slot:metrics-items>
        <div class="metrics-grid-wrap">
          <div class="metrics-grid-item">
            <div class="metrics-icon">
              <svg-icon icon="installs-metrics-icon"/>
            </div>
            <div class="metric-value">
              {{ shortenDigits(metrics['ww'].last_month_installs) }}
            </div>
            <div class="metrics-title">
              {{ metrics['ww'].last_month }} Installs 🌐
            </div>
          </div>
        </div>
        <div class="metrics-grid-wrap">
          <div class="metrics-grid-item">
            <div class="metrics-icon">
              <svg-icon icon="installs-metrics-icon"/>
            </div>
            <div class="metric-value">
              {{ shortenDigits(metrics['ww']['12_months']) }}
            </div>
            <div class="metrics-title">
              Last 12 months Installs 🌐
            </div>
          </div>
        </div>
        <div class="metrics-grid-wrap">
          <div class="metrics-grid-item">
            <div class="metrics-icon">
              <svg-icon icon="installs-metrics-icon"/>
            </div>
            <div class="metric-value">
              {{ shortenDigits(metrics['ww'].total) }}
            </div>
            <div class="metrics-title">
              Total Installs 🌐
            </div>
          </div>
        </div>
      </template>
    </modal-app-metrics-new>

    <div class="chart-container overlaid">
      <template v-if="showChartBlock">
        <div class="checkboxes-block type-2 cumulative-checkbox">
          <input type="checkbox"
                 v-model="showCumulative"
                 id="popularity-checkbox">
          <label for="popularity-checkbox">
            Cumulative
          </label>
        </div>

        <installs-chart :show-revenue="false"
                        :chart-data="showCumulative ? appInstallsRevenue?.cumulative_data : appInstallsRevenue?.worldwide_chart_data" />
      </template>
    </div>
  </div>

  <div class="installs-chart">
    <div class="tab-component-title" id="installs-revenue-chart">
        Estimated Installs & Revenue Chart
    </div>

    <div class="charts-controls"
         v-if="isChartDataLoaded">
      <div class="search-locales-block sub-select">
        <custom-inline-search placeholder="Select country"
                              @search-input-changed="countriesLocalesInputChanged"
                              :with-result-slots="true"
                              :min-input-length="0"
                              :show-caret="true"
                              :full-width-content="true"
                              results-max-height="256px"
                              :initial-data="installsCurrentCountryData?.name"
                              :config="countryLocalesConfig"
                              :search-results="countriesLocalesObject">

          <template v-slot:heading-info>
            <dynamic-image classes="country-flag"
                           :width="18"
                           :height="14"
                           :size="32"
                           :country-code="installsCurrentCountryData?.code"/>
            {{ installsCurrentCountryData?.name }}
          </template>

          <template v-slot:results>
            <template
              v-if="countriesLocalesComputed?.popular?.length > 0 || countriesLocalesComputed?.other?.length > 0">
              <div class="results-group"
                   v-if="countriesLocalesComputed?.popular?.length > 0">
                <div v-for="result in countriesLocalesComputed?.popular"
                     @click="popularityCountrySelectHandler(result.code, result.name)"
                     class="result-item"
                     v-if="result?.code !== 'Worldwide'">
                  <template v-if="result?.code">
                    <dynamic-image classes="country-flag"
                                   :width="18"
                                   :height="14"
                                   :size="32"
                                   :country-code="result?.code"/>
                  </template>
                  <span class="item-name">{{ result?.name }}</span>
                </div>
              </div>

              <div class="results-group"
                   v-if="countriesLocalesComputed?.other?.length > 0">
                <div class="group-title">Other</div>
                <div v-for="result in countriesLocalesComputed?.other"
                     @click="popularityCountrySelectHandler(result.code, result.name)"
                     class="result-item">

                  <template v-if="result?.code && result?.code !== 'WORLDWIDE'">
                    <dynamic-image classes="country-flag"
                                   :width="18"
                                   :height="14"
                                   :size="32"
                                   :country-code="result?.code"/>
                  </template>
                  <span class="item-name"
                        v-if="result?.code !== 'WORLDWIDE'">{{ result?.name }}</span>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="results-group">
                <div class="text">No data found</div>
              </div>
            </template>
          </template>
        </custom-inline-search>
      </div>
    </div>

    <div class="preloader-wrap"
         v-if="!isChartDataLoaded">
      <preloader-table-small :loader-size="'40px'"></preloader-table-small>
    </div>
    <template v-else>
      <modal-app-metrics-new :is-metrics-data-loaded="true"
                             classes="new-metrics-wrap">
        <template v-slot:metrics-items>
          <div class="metrics-grid-wrap">
            <div class="metrics-grid-item">
              <div class="metrics-icon">
                <svg-icon v-if="hideInstalls" icon="locked-metric"/>
                <svg-icon v-else icon="installs-metrics-icon"/>
              </div>
              <div v-if="hideInstalls"
                   class="metric-value upgrade-text"
                   @click="openPlansModal">
                Pro Plan
                <svg-icon icon="arrow-right"/>
              </div>
              <div v-else class="metric-value">
                {{ shortenDigits(metrics['12_months'].ww_installs) }}
              </div>
              <div class="metrics-title">
                Est. Installs 🌐 (12 months)
              </div>
            </div>
          </div>
          <div class="metrics-grid-wrap">
            <div class="metrics-grid-item">
              <div class="metrics-icon">
                <svg-icon v-if="hideInstalls" icon="locked-metric"/>
                <svg-icon v-else icon="revenue-metrics-icon"/>
              </div>
              <div v-if="hideInstalls"
                   class="metric-value upgrade-text"
                   @click="openPlansModal">
                Pro Plan
                <svg-icon icon="arrow-right"/>
              </div>
              <div v-else class="metric-value">
                ${{ shortenDigits(metrics['12_months'].ww_revenue) }}
              </div>
              <div class="metrics-title">
                Est. Revenue 🌐 (12 months)
              </div>
            </div>
          </div>
          <div class="metrics-grid-wrap">
            <div class="metrics-grid-item">
              <div class="metrics-icon">
                <svg-icon v-if="hideInstalls" icon="locked-metric"/>
                <svg-icon v-else icon="installs-metrics-icon"/>
              </div>
              <div v-if="hideInstalls"
                   class="metric-value upgrade-text"
                   @click="openPlansModal">
                Pro Plan
                <svg-icon icon="arrow-right"/>
              </div>
              <div v-else class="metric-value">
                {{ shortenDigits(metrics['12_months'].country_installs) }}
              </div>
              <div class="metrics-title">
                Est. Installs
                <dynamic-image classes="flag-icon"
                               :width="16"
                               :height="12"
                               :country-code="installsCurrentCountryData?.code"/>
                (12 months)
              </div>
            </div>
          </div>
          <div class="metrics-grid-wrap">
            <div class="metrics-grid-item">
              <div class="metrics-icon">
                <svg-icon v-if="hideInstalls" icon="locked-metric"/>
                <svg-icon v-else icon="revenue-metrics-icon"/>
              </div>
              <div v-if="hideInstalls"
                   class="metric-value upgrade-text"
                   @click="openPlansModal">
                Pro Plan
                <svg-icon icon="arrow-right"/>
              </div>
              <div v-else class="metric-value">
                ${{ shortenDigits(metrics['12_months'].country_revenue) }}
              </div>
              <div class="metrics-title">
                Est. Revenue
                <dynamic-image classes="flag-icon"
                               :width="16"
                               :height="12"
                               :country-code="installsCurrentCountryData?.code"/>
                (12 months)
              </div>
            </div>
          </div>
        </template>
      </modal-app-metrics-new>

      <div class="chart-container overlaid">
        <div class="overlay" v-if="hideInstalls">
          <svg-icon icon="locker"/>
          <div class="subscription-block">
            <div class="title">Get Installs, Revenue data, and more App insights with Pro Plan</div>
            <div class="button">
              <base-button height="50px"
                           width="180px"
                           font-size="16px"
                           border-radius="4px"
                           :mode="'btn-standard'"
                           @click="openPlansModal">
                Go to Plans
              </base-button>
            </div>
          </div>
        </div>
        <template v-if="showChartBlock">
          <installs-chart :show-revenue="true"
                          :chart-data="appInstallsRevenue?.chart_data" />
        </template>
      </div>
    </template>
  </div>

  <div class="tab-component-title">
    {{ metrics?.last_month?.month }} Estimated Installs & Revenue
  </div>

  <div class="preloader-wrap"
       v-if="!installsDataLoaded">
    <preloader-table-small :loader-size="'40px'"></preloader-table-small>
  </div>

  <modal-app-metrics-new :is-metrics-data-loaded="true"
                         v-if="installsDataLoaded"
                         classes="new-metrics-wrap">
    <template v-slot:metrics-items>
      <div class="metrics-grid-wrap">
        <div class="metrics-grid-item">
          <div class="metrics-icon">
            <svg-icon v-if="hideInstalls" icon="locked-metric"/>
            <svg-icon v-else icon="installs-metrics-icon"/>
          </div>
          <div v-if="hideInstalls"
               class="metric-value upgrade-text"
               @click="openPlansModal">
            Pro Plan
            <svg-icon icon="arrow-right"/>
          </div>
          <div v-else class="metric-value">
            {{ shortenDigits(metrics['last_month'].ww_installs) }}
          </div>
          <div class="metrics-title">
            Est. Installs 🌐
          </div>
        </div>
      </div>
      <div class="metrics-grid-wrap">
        <div class="metrics-grid-item">
          <div class="metrics-icon">
            <svg-icon v-if="hideInstalls" icon="locked-metric"/>
            <svg-icon v-else icon="revenue-metrics-icon"/>
          </div>
          <div v-if="hideInstalls"
               class="metric-value upgrade-text"
               @click="openPlansModal">
            Pro Plan
            <svg-icon icon="arrow-right"/>
          </div>
          <div v-else class="metric-value">
            ${{ shortenDigits(metrics['last_month'].ww_revenue) }}
          </div>
          <div class="metrics-title">
            Est. Revenue 🌐
          </div>
        </div>
      </div>
      <div class="metrics-grid-wrap">
        <div class="metrics-grid-item">
          <div class="metrics-icon">
            <svg-icon v-if="hideInstalls" icon="locked-metric"/>
            <svg-icon v-else icon="installs-metrics-icon"/>
          </div>
          <div v-if="hideInstalls"
               class="metric-value upgrade-text"
               @click="openPlansModal">
            Pro Plan
            <svg-icon icon="arrow-right"/>
          </div>
          <div v-else class="metric-value">
            {{ shortenDigits(metrics['last_month'].country_installs) }}
          </div>
          <div class="metrics-title">
            Est. Installs
            <dynamic-image classes="flag-icon"
                           :width="16"
                           :height="12"
                           :country-code="currentCountry?.code"/>
          </div>
        </div>
      </div>
      <div class="metrics-grid-wrap">
        <div class="metrics-grid-item">
          <div class="metrics-icon">
            <svg-icon v-if="hideInstalls" icon="locked-metric"/>
            <svg-icon v-else icon="revenue-metrics-icon"/>
          </div>
          <div v-if="hideInstalls"
               class="metric-value upgrade-text"
               @click="openPlansModal">
            Pro Plan
            <svg-icon icon="arrow-right"/>
          </div>
          <div v-else class="metric-value">
            ${{ shortenDigits(metrics['last_month'].country_revenue) }}
          </div>
          <div class="metrics-title">
            Est. Revenue
            <dynamic-image classes="flag-icon"
                           :width="16"
                           :height="12"
                           :country-code="currentCountry?.code"/>
          </div>
        </div>
      </div>
    </template>
  </modal-app-metrics-new>

  <div class="installs-table-container overlaid">
    <div class="overlay" v-if="hideInstalls && installsDataLoaded">
      <svg-icon icon="locker"/>
      <div class="subscription-block">
        <div class="title">Get Installs, Revenue data, and more App insights with Pro Plan</div>
        <div class="subtitle">10 000 keywords, unlimited competitors and features for up to 6 of your Apps</div>
        <div class="button">
          <base-button height="50px"
                       width="180px"
                       font-size="16px"
                       border-radius="4px"
                       :mode="'btn-standard'"
                       @click="openPlansModal">
            Go to Plans
          </base-button>
        </div>
      </div>
    </div>
    <div class="installs-table" :class="hideInstalls ? 'installs-table-hidden-data' : ''">
      <data-table :headers="appInstallsRevenueHeaders"
                  :items="appInstallsComputed"
                  :rows-per-page-items="[50, 100, 250, 500, 1000]"
                  :fixed-width="true"
                  :show-actions="false"
                  :with-show-more="true"
                  :show-more-count="10"
                  :show-more-colspan="5"
                  :pagination="pageMeta"
                  :sorting-meta="sortingMeta"
                  :items-per-page="+pageMeta.per_page"
                  :sorting-keys="{direction: 'sort_direction', sorting: 'sort_by', paginate: 'per_page'}"
                  show-totals="top"
                  @update-sort="updateSort">

        <template v-slot:totals>
          <td class="left country-col">
            <div class="inner-content">
              <div class="block-content">
                Totals:
              </div>
            </div>
          </td>
          <td class="right">
            <div class="inner-content">
              <div class="block-content">
                {{ appInstallsRevenue.totals.installs.toLocaleString('ru-RU', {maximumFractionDigits: 0}) }}
              </div>
            </div>
          </td>
          <td class="right">
            <div class="inner-content">
              <div class="block-content">
                100
              </div>
            </div>
          </td>
          <td class="right">
            <div class="inner-content">
              <div class="block-content">
                {{ appInstallsRevenue.totals.revenue.toLocaleString('ru-RU', {maximumFractionDigits: 0}) }}
              </div>
            </div>
          </td>
          <td class="right">
            <div class="inner-content">
              <div class="block-content">
                100
              </div>
            </div>
          </td>
        </template>

        <template v-slot:items="{item}">
          <td v-for="headerItem in appInstallsRevenueHeaders"
              :style="{width: headerItem.width}"
              :class="[headerItem.align, headerItem?.headingClass, {'large-padding': headerItem?.largePadding}, headerItem.isSticky ? 'is-sticky' : '', headerItem.stickyClass ? headerItem.stickyClass : '']">
            <div class="inner-content">
              <template v-for="(tdItem, tdIndex) in item"
                        :key="tdIndex">
                <template v-if="tdIndex === headerItem.value">
                  <template v-if="headerItem?.value === 'country'">
                    <div class="block-content"
                         :style="{maxWidth: headerItem.textWidth}">
                      <span class="flag"
                            v-if="tdItem !== 'WORLDWIDE'">
                        <dynamic-image classes="country-flag"
                                       :width="18"
                                       :height="14"
                                       :size="32"
                                       :country-code="tdItem"/>
                      </span>
                      <span class="country-name">{{ returnCountryName(tdItem) }}</span>
                    </div>
                  </template>

                  <template v-else-if="headerItem?.value === 'installs'">
                    <div class="block-content">{{ tdItem }}
                    </div>
                  </template>

                  <template v-else-if="headerItem?.value === 'installs_percent'">
                    <div class="block-content">{{ tdItem }}</div>
                  </template>

                  <template v-else-if="headerItem?.value === 'revenue'">
                    <div class="block-content">{{ tdItem }}</div>
                  </template>

                  <template v-else-if="headerItem?.value === 'revenue_percent'">
                    <div class="block-content">{{ tdItem }}</div>
                  </template>

                </template>
              </template>
            </div>
          </td>
        </template>
      </data-table>
    </div>
  </div>
</template>

<script>
import {httpRequest} from "@/api";
import {formatDate, shortenDigits} from "@/utils/utils";
import {appInstallsRevenueTableConfig} from "@/configs/page-tables-configs/app-installs-revenue";
import {mapGetters} from "vuex";
import RankingsTable from "@/views/CompetitorsFinderPage/components/RankingsTable/index.vue";
import CustomTextareaWithChips from "@/components/Forms/CustomTextareaWithChips/index.vue";
import PreloaderTableSmall from "@/components/UI/PreloaderTableSmall/index.vue";
import CustomSelectWithSearch from "@/components/Forms/CustomSelectWithSearch/index.vue";
import DataTable from "@/views/DashboardPage/components/DataTable/index.vue";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import CustomInlineSearch from "@/components/Forms/CustomInlineSearch/index.vue";
import InstallsChart from "@/components/AppDetailsComponent/components/InstallsChart/index.vue";
import ModalAppMetricsNew from "@/views/Analyze/AnalyzeApp/components/ModalAppMetrics";

export default {
  name: "InstallsAndRevenue",
  props: {
    appData: {
      type: Object,
    },
    filterCountriesArray: {
      type: Array
    },
    filterLocalesArray: {
      type: Array
    }
  },
  components: {
    ModalAppMetricsNew,
    InstallsChart,
    SvgIcon,
    'rankings-table': RankingsTable,
    'textarea-with-chips': CustomTextareaWithChips,
    'preloader-table-small': PreloaderTableSmall,
    'custom-select-search': CustomSelectWithSearch,
    DataTable,
    'custom-inline-search': CustomInlineSearch,
  },
  data() {
    return {
      showCumulative: false,
      metrics: {},
      appInstallsRevenueHeaders: appInstallsRevenueTableConfig.headers,
      addedKeywordsArray: [],
      modalData: {
        appMetaData: [],
      },
      appInstallsRevenue: {},
      installsDataLoaded: false,
      isChartDataLoaded: false,
      originalInstallsCountriesList: [],
      fullOriginalInstallsCountriesList: [],
      installsCountriesInputValue: '',
      installsCurrentCountryData: {
        name: '',
        code: ''
      },
      sortingMeta: {
        sort_by: 'installs',
        sort_direction: 'desc',
      },
      countryLocaleSearchInputValue: '',
      countryLocalesConfig: {
        border: 'none',
        boxShadow: '0 6px 12px 0 rgba(160, 190, 221, 0.24)',
        height: '36px',
        width: '256px',
      },
      countriesLocalesObject: {},
      showChartBlock: true,
    }
  },
  async mounted() {
    this.installsDataLoaded = false;
    this.isChartDataLoaded = false;

    const currCountryData = {
      name: this.currentCountry?.name,
      code: this.currentCountry?.code,
    }

    this.installsCurrentCountryData = {...currCountryData};
    await this.fetchInstallsRevenueData();
  },
  methods: {
    shortenDigits,
    async fetchInstallsRevenueData(countryCode) {
      this.installsDataLoaded = countryCode !== undefined;
      this.isChartDataLoaded = false;
      const url = '?country_code=' + this.currentCountry?.code + '&chart_country_code=' + this.installsCurrentCountryData.code
        + '&app_id=' + this.appData?.id;
      const response = await httpRequest(
        'GET',
        process.env.VUE_APP_API_URL + this.$ApiUrls.competirorsFinder.INSTALLS_REVENUE + url
      );

      let tableData = [];
      let countriesData = [];
      let fullCountriesData = [];

      if (response?.countries_list) {
        for (const key in response?.countries_list) {
          countriesData.push({
            code: key,
            name: response?.countries_list[key]
          });
        }
        this.originalInstallsCountriesList = [...countriesData];
      }

      if (response?.all_countries_list) {
        for (const key in response?.all_countries_list) {
          fullCountriesData.push({
            code: key,
            name: response?.all_countries_list[key]
          });
        }
        this.fullOriginalInstallsCountriesList = [...fullCountriesData];
      }

      let popularCountriesCodes = [];
      if (response?.countries_list) {
        popularCountriesCodes = Object.keys(response?.countries_list);
      }

      this.countriesLocalesObject = {
        popular: this.originalInstallsCountriesList,
        other: this.fullOriginalInstallsCountriesList?.filter(country => !popularCountriesCodes.includes(country?.code)),
      };

      this.countriesLocalesObject?.popular.sort((a, b) => (a?.name > b?.name) ? 1 : -1);
      this.countriesLocalesObject?.other.sort((a, b) => (a?.name > b?.name) ? 1 : -1);

      if (response?.table_data) {
        for (const key in response.table_data) {
          tableData.push({
            installs: response.table_data[key]?.installs?.toLocaleString('ru-RU', {maximumFractionDigits: 0}),
            installs_origin: response.table_data[key]?.installs,
            revenue: response.table_data[key]?.revenue?.toLocaleString('ru-RU', {maximumFractionDigits: 0}),
            revenue_origin: response.table_data[key]?.revenue,
            installs_percent: response.table_data[key]?.installs_percent?.toFixed(0),
            revenue_percent: response.table_data[key]?.revenue_percent?.toFixed(0),
            country: key,
          });
        }
      }

      this.appInstallsRevenue = {
        chart_data: {...response?.chart_data},
        cumulative_data: {...response?.cumulative},
        worldwide_chart_data: {...response?.worldwide_chart_data},
        totals: {...response?.totals},
        table_data: tableData,
        countries_list: {result: countriesData},
      };

      this.metrics = response.metrics;

      this.isChartDataLoaded = true;
      this.installsDataLoaded = true;
    },
    returnCountryName(countryCode) {
      const currCountry = this.originalInstallsCountriesList?.filter(country => country?.code?.toLowerCase() === countryCode?.toLowerCase());
      return currCountry[0]?.name;
    },
    formatDate(val, type) {
      return formatDate(val, type);
    },
    async popularityCountrySelectHandler(code, name) {
      const currCountryData = {
        name: name,
        code: code,
      }

      this.installsCurrentCountryData = {...currCountryData};
      this.countryLocaleSearchInputValue = "";
      await this.fetchInstallsRevenueData(code);
    },
    async updateSort(data) {
      const tempMeta = {
        sort_by: data?.sort_by,
        sort_direction: data?.sort_direction,
      }

      this.sortingMeta = {...tempMeta};

    },
    countriesLocalesInputChanged(value) {
      this.countryLocaleSearchInputValue = value;
    },
    openPlansModal() {
      this.$store.dispatch('INVOKE_USER_LIMITS_MANUAL');
    },
  },
  computed: {
    ...mapGetters([
      'currentApp',
      'currentCountry',
      'currentCountryManual',
      'promiseSettledData',
      'pageMeta',
      'sidebarIsCollapsed',
      'userSubscription',
    ]),
    appInstallsComputed() {
      if (this.appInstallsRevenue?.table_data) {
        let tableData = [...this.appInstallsRevenue?.table_data];

        if (this.sortingMeta?.sort_direction === 'desc') {
          tableData.sort((a, b) => (a[this.sortingMeta?.sort_by + '_origin'] < b[this.sortingMeta?.sort_by + '_origin']) ? 1 : -1);
        } else {
          tableData.sort((a, b) => (a[this.sortingMeta?.sort_by + '_origin'] > b[this.sortingMeta?.sort_by + '_origin']) ? 1 : -1);
        }

        return tableData;
      }
      return this.appInstallsRevenue?.table_data;
    },
    windowWidth() {
      return window.innerWidth;
    },
    countriesLocalesComputed() {
      const tempArray = {...this.countriesLocalesObject};
      const loweredInput = this.countryLocaleSearchInputValue?.toLowerCase();

      return {
        popular: tempArray?.popular?.filter(locale => locale?.name?.toLowerCase()?.includes(loweredInput)),
        other: tempArray?.other?.filter(country => country?.name?.toLowerCase()?.includes(loweredInput)),
      }
    },
    hideInstalls() {
      return this.userSubscription?.plan_name === 'Free';
    },
  },
  watch: {
    async currentCountryManual(newVal, oldVal) {
      if (newVal?.code !== oldVal?.code) {
        this.installsDataLoaded = false;

        const currCountryData = {
          name: newVal?.name,
          code: newVal?.code,
        }

        this.installsCurrentCountryData = {...currCountryData};
        await this.fetchInstallsRevenueData();
      }
    },
    async currentApp(newVal, oldVal) {
      if (newVal?.id !== oldVal?.id && oldVal?.id) {

        setTimeout(async () => {
          this.installsDataLoaded = false;

          const currCountryData = {
            name: this.currentCountry?.name,
            code: this.currentCountry?.code,
          }

          this.installsCurrentCountryData = {...currCountryData};
          await this.fetchInstallsRevenueData();
        }, 500);
      }
    },
    sidebarIsCollapsed(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.showChartBlock = false;
        setTimeout(() => {
          this.showChartBlock = true;
        }, 500)
      }
    }
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>
