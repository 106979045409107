<template>
  <div class="root">
    <button
      class="slider-arrow left-arrow"
      @click="swipeLeft"
      v-if="showLeftArrow"
    >
      <img src="@/assets/svg/arrow-left-icon.svg" alt="Arrow left" />
    </button>
    <carousel
      ref="carouselRef"
      :breakpoints="breakpoints"
      snapAlign="start"
    >
      <slide v-for="slide in slides" :key="slide">
        <img
          style="cursor: pointer;"
          :src="slide?.croppedSrc"
          :class="slide?.direction"
          @click="$emit('onClickSlide', slide)"
        />
      </slide>
      <template #addons>
        <navigation v-if="withNavigation" />
        <pagination v-if="withPagination" />
      </template>
    </carousel>
    <button
      class="slider-arrow right-arrow"
      @click="swipeRight"
      v-if="showRightArrow"
    >
      <img src="@/assets/svg/arrow-left-icon.svg" alt="Arrow right" />
    </button>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { ref } from "vue";
import { Carousel, Slide, Navigation, Pagination } from "vue3-carousel";

export default {
  name: "CustomCarousel",
  components: {
    'carousel': Carousel,
    'slide': Slide,
    'navigation': Navigation,
    'pagination': Pagination
  },
  props: {
    slides: {
      type: Array,
      default: [],
    },
    breakpoints: {
      type: Object,
    },
    onClickSlide: {
      type: Function,
    },
    withNavigation: {
      type: Boolean,
      default: false,
    },
    withPagination: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    swipeLeft() {
      this.carouselRef.prev();
    },
    swipeRight() {
      this.carouselRef.next();
    },
  },
  computed: {
    showLeftArrow() {
      return this.carouselRef?.data.currentSlide.value > 0;
    },
    showRightArrow() {
      return (
        this.carouselRef?.data.currentSlide?.value <
        this.carouselRef?.data.maxSlide.value
      );
    },
  },
  setup() {
    const carouselRef = ref(null);

    return {
      carouselRef,
    };
  },
};
</script>

<style lang="scss" src="./styles.scss"></style>
