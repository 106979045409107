<template>
  <div class="keyword-explorer-data"
       :class="classes">
    <div class="top-component-block">
      <div class="left-side">
        <slot></slot>
      </div>
      <div class="right-side">
        <order-radaso-button :pre-selected="['keywords-research']"/>

        <template v-if="currentApp?.store?.key === 'APP_STORE'">
          <div class="device-icons">
            <div class="device-icon btn btn-default"
                 @click="changeDevice('iphone')"
                 :class="{active: liveSearchDevice === 'iphone'}">iPhone
            </div>
            <div class="device-icon btn btn-default"
                 :class="{active: liveSearchDevice === 'ipad'}"
                 @click="changeDevice('ipad')">iPad
            </div>
          </div>
        </template>
      </div>
    </div>

    <div class="screenshots-block-container"
         v-if="showScreenshotsBlock">
      <modal-app-screenshots :screenshots-data="appScreenshotsData"
                             :is-screenshots-data-loaded="screenshotsDataLoaded"
                             :breakpoints-props="screenshotsProps"
                             :classes="'explorer-screenshots'"
                             :current-competitor-store="clickedItem?.store">
        <div class="preloader-wrap">
          <preloader-table-small loader-size="50px"></preloader-table-small>
        </div>
      </modal-app-screenshots>

      <div class="close-trigger-block">
        <div class="close-trigger"
             @click="screenShotsClicked">Hide {{ currentApp?.store?.key === 'APP_STORE' ? 'App Store' : 'Google' }}
          Images
        </div>
      </div>
    </div>

    <div class="data-columns">
      <div class="left-col">
        <div class="live-search-metrics">
          <div class="metrics-grid tablet-hidden mobile-hidden">
            <div class="metrics-grid-wrap">
              <div class="metrics-grid-item">
                <div class="metric-value"
                     :class="{skeleton: skeletonBoxesShown}">{{ metricsData?.popularity }}
                </div>
                <div class="metric-title">
                  <span>Popularity</span>

                  <custom-tooltip v-if="currentApp?.store?.key === 'APP_STORE'"
                                  :classes="'metric-chart-icon'"
                                  :style="{minWidth: '100px'}"
                                  @click="popularityChartClicked">
                    <template v-slot:slotTrigger>
                      <svg-icon icon="chart"/>
                    </template>
                    <template v-slot:slotContent>
                      Open Chart
                    </template>
                  </custom-tooltip>

                </div>
              </div>
            </div>
            <div class="metrics-grid-wrap">
              <div class="metrics-grid-item">
                <div class="metric-value"
                     :class="{skeleton: skeletonBoxesShown}">{{ metricsData?.impression }}
                </div>
                <div class="metric-title">
                  <span>Daily Impr.</span>
                </div>
              </div>
            </div>
            <div class="metrics-grid-wrap">
              <div class="metrics-grid-item">
                <div class="metric-value"
                     :class="{skeleton: skeletonBoxesShown}">{{ metricsData?.app_rank }}
                </div>
                <div class="metric-title">
                  <span>Your App Ranking</span>
                </div>
              </div>
            </div>
            <div class="metrics-grid-wrap">
              <div class="metrics-grid-item">
                <div class="metric-value"
                     :class="{skeleton: skeletonBoxesShown}">{{ metricsData?.competitors }}
                </div>
                <div class="metric-title">
                  <span>Сompetitors (Top 10)</span>
                </div>
              </div>
            </div>
          </div>

          <div class="average-metrics-top top-block desktop-hidden">
            <template v-if="skeletonBoxesShown">
              <preloader/>
            </template>
            <template v-else>
              <div class="table-metrics">
                <div class="basic-table">
                  <div class="table-row">
                    <div class="left-side">Popularity</div>
                    <div class="right-side">{{ metricsData?.popularity }}</div>
                  </div>

                  <div class="table-row">
                    <div class="left-side">Daily Impr.</div>
                    <div class="right-side">{{ metricsData?.impression }}</div>
                  </div>

                  <div class="table-row">
                    <div class="left-side">Your App Ranking</div>
                    <div class="right-side">{{ metricsData?.app_rank }}</div>
                  </div>

                  <div class="table-row">
                    <div class="left-side">Сompetitors (Top 10)</div>
                    <div class="right-side">{{ metricsData?.competitors }}</div>
                  </div>

                </div>
              </div>
            </template>
          </div>
        </div>

        <div class="autosuggest-block-heading">
          <div class="title">Keywords Autosuggest</div>
          <router-link tag="div"
                       class="see-all"
                       :to="{name: 'KeywordsAutosuggest', query: {keyword: currentKeyword}}">View all
          </router-link>
        </div>

        <div class="autosuggest-keywords block-container">
          <div class="block-content">
            <template v-if="suggestsSkeletonBoxesShown">
              <div class="basic-table autosuggest-table">
                <div class="table-row"
                     v-for="item in 11">
                  <div class="left-side">
                    <div class="text-block">
                      <div class="skeleton long-skeleton-box"></div>
                    </div>
                  </div>
                  <div class="right-side">
                    <div class="skeleton short-skeleton-box"></div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="basic-table autosuggest-table">
                <div class="table-row header-row">
                  <div class="left-side">
                    <div class="text-block head-item">
                      Keywords
                    </div>
                  </div>
                  <div class="right-side">
                    <div class="sap-block head-item">
                      Popularity
                    </div>
                    <div class="add-keywords-icons head-item">
                      Add
                    </div>
                  </div>
                </div>
                <div class="table-row"
                     v-for="item in autoSuggestedKeywords">
                  <div class="left-side">
                    <div class="text-block">
                      {{ item?.keyword }}
                    </div>
                    <custom-tooltip :classes="'tool-item blue translate'"
                                    :link-url="'https://translate.google.com/?sl=auto&tl=en&text=' + item?.keyword"
                                    width="80px">
                      <template v-slot:slotTrigger>
                        <svg-icon icon="translate"/>
                      </template>
                      <template v-slot:slotContent>
                        Translate
                      </template>
                    </custom-tooltip>
                  </div>
                  <div class="right-side">
                    <div class="sap-block"
                         v-if="item?.SAP">{{ item?.SAP }}
                    </div>
                    <div class="add-keywords-icons">
                      <custom-tooltip v-if="item.is_included"
                                      :classes="'check'"
                                      direction-horizontal="left"
                                      :straight="true"
                                      :with-padding="true"
                                      width="160px"
                                      text-align="center">
                        <template v-slot:slotTrigger>
                          <svg-icon icon="check"/>
                        </template>
                        <template v-slot:slotContent>
                          Added to My Keywords
                        </template>
                      </custom-tooltip>

                      <custom-tooltip v-else
                                      :classes="'add-icon'"
                                      text-align="center"
                                      direction-horizontal="left"
                                      :straight="true"
                                      width="130px"
                                      @click="addSingleKeywordHandler([item])">
                        <template v-slot:slotTrigger>
                          <svg-icon icon="plus"/>
                        </template>
                        <template v-slot:slotContent>
                          Add to My Keywords
                        </template>
                      </custom-tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div class="right-col">
        <div class="live-search-block block-container">
          <div class="block-heading">
            <div class="title">
              Live Search <span class="store_query_correct_hint" v-if="liveSearchResults.querySugg">Results for: <b>{{liveSearchResults.querySugg}}</b></span>
            </div>
            <div class="display-flex">
              <router-link tag="div"
                           class="see-all"
                           :to="{name: 'LiveSearch'}">
                See all
              </router-link>
              <translate-tool :active="translateIsActive"
                              class="left-tooltip"
                              v-if="liveSearchResults?.results"
                              @translate-clicked="translateClickHandler">
                <template v-slot:text>
                  Translate all applications into English
                </template>
              </translate-tool>
            </div>
          </div>

          <template v-if="reloading">
            <div>
              <preloader-table-small :loader-size="'40px'"></preloader-table-small>
            </div>
          </template>
          <template v-else-if="liveSearchSkeletonShown">
            <div class="live-search-block">
              <div class="app-search-billet-wrap"
                   v-for="item in 10">
                <div class="app-billet">
                  <div class="billet-content">
                    <div class="position mobile-hidden alternative-position">#{{ item }}</div>

                    <div class="app-logo">
                      <div class="empty-logo"></div>
                    </div>

                    <div class="app-info alternative-position">
                      <div class="app-details-top">
                        <div class="skeleton long-skeleton-box"></div>
                      </div>
                      <div class="app-details-bottom mobile-hidden">
                        <div class="skeleton long-skeleton-box"></div>
                      </div>

                      <div class="actions">
                        <div class="add-app-block">
                          <div class="skeleton short-skeleton-box"></div>
                        </div>

                        <div class="competitors-block mobile-hidden">
                          <div class="skeleton short-skeleton-box"></div>
                        </div>
                      </div>

                    </div>

                    <div class="app-details-bottom mobile-visible">
                      <div class="skeleton long-skeleton-box"></div>
                    </div>

                    <div class="competitors-block mobile-visible">
                      <div class="skeleton long-skeleton-box"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div v-else-if="hasError">
            <div>
              Something went wrong 😱 <span @click="tryAgain" class="try-again">Try Again</span>
            </div>
          </div>
          <div v-else-if="liveSearchResults?.results?.length === 0">
            {{  noAppsLabel }}
          </div>
          <div v-else
               class="live-search-block">
            <live-search-billet v-for="(item, index) in liveSearchResults?.results"
                                @live-app-clicked="liveAppClickHandler"
                                @competitor-changed="competitorChanged"
                                :key="item.id"
                                :item-data="item"
                                :item-index="item.positionIndex"
                                :no-screenshots="true"
                                :alternative-position-place="true"
                                :current-app="currentApp"
                                @open-app-modal-clicked="competitorsItemClickHandler">

            </live-search-billet>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-24">
      <rank-expand-wrap
          :key="currentApp.id + currentCountry.code + updateExploreComponent"
          :app="currentApp"
          :country="currentCountry"
          :keyword="currentKeyword"
      ></rank-expand-wrap>
    </div>

    <template v-if="skeletonBoxesShown">
      <preloader/>
    </template>
    <template v-else>
      <div class="average-metrics-top block-container">
        <template v-if="keywordsTopCategory">
          <div class="title">Average metrics by category (Top 10)</div>
          <div class="progress-metrics">
            <div class="progress-metric-item"
                 v-for="metric in keywordsTopCategory">
              <div class="metric-header">
                <div class="left-side">{{ metric?.title }}</div>
                <div class="right-side">{{ metric?.distribution_percent }}%</div>
              </div>
              <progress-bar :width="metric?.distribution_percent + '%'"/>
            </div>
          </div>
        </template>

        <div class="title">Top Apps insights</div>
        <div class="table-metrics">
          <div class="basic-table">
            <div class="table-row">
              <div class="left-side">Number of competitors (Top 10)<span
                  class="mobile-visible">:&nbsp;<strong>{{
                  keywordsAverageMetrics.competitors
                }}</strong></span>
              </div>
              <div class="right-side mobile-hidden">{{ keywordsAverageMetrics.competitors }}</div>
            </div>

            <div class="table-row">
              <div class="left-side">Average rating<span class="mobile-visible">:&nbsp;<strong>{{
                  keywordsAverageMetrics.avg_rating
                }}</strong></span>
              </div>
              <div class="right-side mobile-hidden">{{ keywordsAverageMetrics.avg_rating }}</div>
            </div>

            <div class="table-row">
              <div class="left-side">Average number of votes<span class="mobile-visible">:&nbsp;<strong>{{
                  keywordsAverageMetrics.avg_votes
                }}</strong></span>
              </div>
              <div class="right-side mobile-hidden">{{
                  minifyDigitsToLetters(keywordsAverageMetrics.avg_votes)
                }}
              </div>
            </div>

            <div class="table-row">
              <div class="left-side">Average age of apps (Top 10)<span class="mobile-visible">:&nbsp;<strong>{{
                  keywordsAverageMetrics.avg_age_in_days
                }}</strong></span>
              </div>
              <div class="right-side mobile-hidden">{{ keywordsAverageMetrics.avg_age_in_days }}</div>
            </div>

            <div class="table-row">
              <div class="left-side">Percentage of free apps<span class="mobile-visible">:&nbsp;<strong>{{
                  keywordsAverageMetrics.free_percent
                }}%</strong></span>
              </div>
              <div class="right-side mobile-hidden">{{ keywordsAverageMetrics.free_percent }}%</div>
            </div>

            <div class="table-row">
              <div class="left-side">Average price of paid ($)<span class="mobile-visible">:&nbsp;<strong>{{
                  keywordsAverageMetrics.avg_price
                }}</strong></span>
              </div>
              <div class="right-side mobile-hidden">{{ keywordsAverageMetrics.avg_price }}</div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <slot name="bottom-link"></slot>
  </div>

  <custom-modal :opened="showTableChartModal"
                :max-width="728"
                class-name="popularity-chart-modal"
                @closeModal="closeModal('showTableChartModal')">
    <sap-chart-modal :current-country-code="currentCountry?.code"
                     :current-country-name="currentCountry?.name"
                     :current-keyword-item="currentKeyword"/>
  </custom-modal>

  <details-modal v-if="showAppDetailsModal"
                     :opened="showAppDetailsModal"
                     :app-data="clickedItem"
                     @competitor-changed="modalCompetitorChanged"
                     :change-competitor-in="true"
                     @modal-closed="detailsModalClosed"/>
</template>

<script>
import {formatDate, getCookie} from "@/utils/utils";
import {keywordsPopularChartOptions} from "@/configs/keywords-popular-charts/keywords-popular-charts";
import {httpRequest} from "@/api";
import {mapGetters} from "vuex";
import Preloader from "@/components/UI/Preloader";
import SapChartModal from "@/components/Modals/SapChartModal/index.vue";
import ProgressBar from '@/components/UI/ProgressBar/index';
import LiveSearchBillet from "@/components/Billets/LiveSearchBillet/index.vue";
import DetailsModal from "@/components/AppDetailsComponent/DetailsModal/index.vue";
import ModalAppScreenshots from "@/views/CompetitorsFinderPage/components/ModalAppScreenshots";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import {UserAppsArrayCreator} from "@/utils/factories";
import RankExpandWrap from "../../views/Analyze/KeywordExplorer/RankExpandWrap.vue";
import ExpandRankTableChart from "../DataTables/Tools/ExpandRankTableChart/ExpandRankTableChart.vue";
import TranslateTool from "@/components/DataTables/Tools/TranslateTool/index.vue";
import translates from "@/api/modules/translates";
import axios from "axios";
import competitorState from "@/api/modules/competitor-state";
import OrderRadasoButton from "@/components/OrderRadaso/Button/index.vue";

export default {
  name: "KeywordExplorerComponent",
  props: {
    currentKeyword: {
      type: String,
    },
    updateExploreComponent: {
      type: Number
    },
    classes: {
      type: String,
    }
  },
  components: {
    OrderRadasoButton,
    TranslateTool,
    ExpandRankTableChart,
    RankExpandWrap,
    SvgIcon,
    'preloader': Preloader,
    'sap-chart-modal': SapChartModal,
    'progress-bar': ProgressBar,
    'live-search-billet': LiveSearchBillet,
    'modal-app-screenshots': ModalAppScreenshots,
    DetailsModal,
  },
  data() {
    return {
      skeletonBoxesShown: true,
      suggestsSkeletonBoxesShown: true,
      showTableChartModal: false,
      showPopularChartLoader: false,
      liveSearchSkeletonShown: true,
      showAppDetailsModal: false,
      screenshotsDataLoaded: false,
      showScreenshotsBlock: false,
      metricsData: {},
      liveSearchDevice: 'iphone',
      autoSuggestedKeywords: [],
      keywordsTopCategory: [],
      clickedItem: {},
      appScreenshotsData: [],
      screenshotsProps: {
        vertical: {
          320: {
            itemsToShow: 2,
            snapAlign: 'start',
          },
          767: {
            itemsToShow: 3,
            snapAlign: 'start',
          },
          1024: {
            itemsToShow: 8,
            snapAlign: 'start',
          },
        },
        horizontal: {
          320: {
            itemsToShow: 1,
            snapAlign: 'start',
          },
          767: {
            itemsToShow: 2,
            snapAlign: 'start',
          },
          1024: {
            itemsToShow: 4,
            snapAlign: 'start',
          },
        }
      },
      translateIsActive: false,
      competitorsCancelToken: axios.CancelToken.source(),
      hasError: false,
      reloading: false,
    }
  },
  async mounted() {
    this.liveSearchDevice = this.currentApp?.store?.key === 'APP_STORE' ? 'iphone' : 'mobile';
    this.skeletonBoxesShown = true;
    await this.fetchLiveSearchResults('reset');
    await this.fetchDataBundle();
    this.skeletonBoxesShown = false;
  },
  methods: {
    async tryAgain() {
      this.modalDataLoader = false;
      this.reloading = true;
      await this.fetchLiveSearchResults('reset');
      this.reloading = false;
    },
    async liveSearchDeviceChanged(device) {
      this.liveSearchDevice = device;
      this.currentLiveSearchPage = 0;
    },
    async popularityChartClicked() {
      this.openModal('showTableChartModal');

      await this.fetchPopularityChartResults();
    },
    getPopularData() {
      let popularityArray = [];
      for (const key in this.popularityChartResults) {
        popularityArray.push({
          date: formatDate(key, 'month-day-year'),
          rankData: this.popularityChartResults[key]
        });
      }

      let dates = [];
      let rank = [];
      for (const key in this.popularityChartResults) {
        dates.push(formatDate(key, 'month-day-year'));
        rank.push(this.popularityChartResults[key]);
      }

      const rankingData = popularityArray.sort(function (a, b) {
        const dateA = new Date(a.date).getTime();
        const dateB = new Date(b.date).getTime();
        return dateA < dateB ? -1 : 1;
      });

      let self = this;

      return {
        ...keywordsPopularChartOptions,
        xAxis: {
          categories: rankingData.map(item => item.date),
          labels: {
            // step: steps,
            // step: 1,
            // align: 'left',
            // useHTML: true,
            rotation: -45,
            style: {
              color: '#a2aabe',
              fontSize: '14px',
              textOverflow: 'none',
              whiteSpace: 'nowrap',
            },
            formatter: function () {
              if (rankingData?.length === 12) {
                // if (!this.isFirst && !this.isLast) {
                return this.value;
                // }
              } else if (rankingData?.length > 4 && rankingData?.length < 12) {
                return this.value;
              } else {
                return this.value;
              }
            },
          }
        },
        yAxis: {
          title: false,
          labels: {
            style: {
              color: '#a2aabe',
              fontSize: 14
            },
            formatter: function () {
              return this.value;
            },
          },
          gridLineDashStyle: 'longdash',
        },
        series: [{
          data: rankingData.map(item => item.rankData.SAP),
          lineWidth: 3,
          pointHitRadius: 10,
          marker: {
            states: {
              hover: {
                fillColor: 'white',
                radius: 3,
                lineWidth: 1,
                lineColor: '#7688ff',
              }
            }
          },
          // enableMouseTracking: false
        }],
        options: {
          scales: {
            xAxes: [{
              gridLines: {
                display: false,
              },
            }],
            yAxes: [{
              stacked: true,
              gridLines: {
                borderDash: [8, 4],
                color: "#f0f3fd",
                zeroLineColor: 'transparent',
                drawBorder: false,
                drawOnChartArea: true,
              },
              type: 'linear',
            }]
          },
        },
        tooltip: {
          enabled: true,
          width: 100,
          distance: 20,
          padding: 8,
          outside: false,
          useHTML: true,
          backgroundColor: '#fff',
          borderColor: '#d7dde7',
          borderRadius: 4,
          shadow: false,
          shape: "square",
          hideDelay: 15,
          formatter: function () {
            let sapDifference = null;
            const category = this.point.category;
            for (const key in self.popularityChartResults) {
              if (category === formatDate(key, 'month-day-year')) {
                sapDifference = self.popularityChartResults[key]?.difference;
                break;
              }
            }

            const differenceColor = sapDifference > 0 ? 'green' : 'red';
            const differenceRenderValue = sapDifference > 0 ? '+' + sapDifference : sapDifference;
            let differenceBlock = '';

            if (sapDifference !== 0 && sapDifference !== null) {
              differenceBlock = `<span class="difference ${differenceColor}">${differenceRenderValue}</span>`;
            }

            return `<div class="tooltip-inner-container">${this.point.category}
                      <br />
                      <span class="rank">Popularity: <strong>${this.point.y}</strong>
                      ${differenceBlock}
                      </span>
                    </div>`
          }
        },
      };
    },
    async fetchPopularityChartResults(period) {
      const store = this.currentApp?.store?.key ?? 'APP_STORE';
      this.showPopularChartLoader = true;

      const url = process.env.VUE_APP_API_URL
          + this.$ApiUrls.keywordsTracking.POPULARITY_DYNAMICS
          + '?keyword=' + this.currentKeyword?.toLowerCase()
          + '&country_code=' + this.currentCountry?.code
          + '&period=week'
          + '&store_key=' + store;

      const result = await httpRequest('GET', url);
      this.$store.dispatch('keywordsTracking/SET_POPULARITY_CHART_RESULTS', result?.popularity_dynamics);
      this.showPopularChartLoader = false;
    },
    async fetchDataBundle(countryCode) {
      const currentAppId = getCookie('currentAppId');
      const competitorsUrl = '?app_id=' + getCookie('currentAppId');
      const country = countryCode ?? this.currentCountry?.code;

      if (!currentAppId || currentAppId === 'undefined') {
        return;
      }

      const promiseUrls = [
        {
          url: process.env.VUE_APP_API_URL
              + this.$ApiUrls.liveSearch.KEYWORDS_TOP_AVERAGE_METRICS
              + '?country_code=' + country
              + '&app_id=' + currentAppId
              + '&device=' + this.liveSearchDevice?.toLowerCase()
              + '&keyword=' + encodeURIComponent(this.currentKeyword?.toLowerCase()),
          method: 'GET',
        },
        {
          url: process.env.VUE_APP_API_URL
              + this.$ApiUrls.liveSearch.KEYWORDS_COUNTS
              + '?app_id=' + currentAppId
              + '&country_code=' + country
              + '&keyword=' + encodeURIComponent(this.currentKeyword?.toLowerCase())
              + '&device=' + this.liveSearchDevice?.toLowerCase(),
          method: 'GET',
        },
        {
          url: process.env.VUE_APP_API_URL
              + this.$ApiUrls.liveSearch.KEYWORDS_TOP_CATEGORY
              + '?country_code=' + country
              + '&device=' + this.liveSearchDevice?.toLowerCase()
              + '&keyword=' + encodeURIComponent(this.currentKeyword?.toLowerCase())
              + '&app_id=' + currentAppId,
          method: 'GET',
        },
        {
          url: process.env.VUE_APP_API_URL
              + this.$ApiUrls.keywordsTracking.SUGGESTED_KEYWORDS
              + '?application_id=' + currentAppId
              + '&country_code=' + country
              + '&keyword=' + encodeURIComponent(this.currentKeyword?.toLowerCase()),
          method: 'GET',
        },
      ];

      this.screenshotsDataLoaded = false;
      this.suggestsSkeletonBoxesShown = true;
      this.skeletonBoxesShown = true;

      await this.$store.dispatch('FETCH_PROMISE_ALL_SETTLED', promiseUrls);

      const keywordsAverageMetrics = this.promiseSettledData?.data[0]?.value;
      const metricsData = this.promiseSettledData?.data[1]?.value;
      const keywordsTopCategory = this.promiseSettledData?.data[2]?.value?.app_categories;
      const suggestion = this.promiseSettledData?.data[3]?.value?.suggestion;

      if (this.promiseSettledData.success) {
        if (metricsData) {
          this.metricsData = {
            total_apps: metricsData?.total_apps ? metricsData?.total_apps.toLocaleString('ru-RU', {minimumFractionDigits: 0}) : 0,
            popularity: metricsData?.popularity ? metricsData?.popularity.toLocaleString('ru-RU', {minimumFractionDigits: 0}) : 0,
            impression: metricsData?.impression ? metricsData?.impression.toLocaleString('ru-RU', {minimumFractionDigits: 0}) : 0,
            app_rank: metricsData?.app_rank ? metricsData?.app_rank?.toFixed(0) : 0,
            competitors: metricsData?.competitors ? metricsData?.competitors.toLocaleString('ru-RU', {minimumFractionDigits: 0}) : 0,
          }
        }

        if (keywordsAverageMetrics) {
          const appAge = keywordsAverageMetrics?.avg_age_in_days ?? 0;
          let avgAppAgeStr = '';

          if (appAge > 0) {
            const years = Math.abs(Math.floor(appAge / 365));
            const daysDiff = Math.abs(Math.floor(appAge - (years * 365)));
            let months = Math.abs(Math.floor(daysDiff / 30));
            if (months === 12) {
              months = 11;
            }
            const yearsStr = years === 0 ? '' : (years === 1 ? `${years} year` : `${years} years`);
            const monthStr = months === 0 ? '' : (months === 1 ? `${months} month` : `${months} months`);
            avgAppAgeStr = `${yearsStr} ${monthStr}`;
          }

          this.keywordsAverageMetrics = {
            avg_age_in_days: avgAppAgeStr,
            avg_price: keywordsAverageMetrics?.avg_price ?? 0,
            avg_rating: keywordsAverageMetrics?.avg_rating ? +keywordsAverageMetrics?.avg_rating.toFixed(1) : 0,
            avg_votes: keywordsAverageMetrics?.avg_votes ? +keywordsAverageMetrics?.avg_votes?.toFixed(0) : 0,
            competitors: keywordsAverageMetrics?.competitors ?? 0,
            free_percent: keywordsAverageMetrics?.free_percent?.toFixed(0) ?? 0,
          };

          this.skeletonBoxesShown = false;
        }

        if (suggestion) {
          this.autoSuggestedKeywords = suggestion.slice(0, 11);
          this.suggestsSkeletonBoxesShown = false;
        }

        this.keywordsTopCategory = null;
        if (keywordsTopCategory) {
          const sortedTopCategories = keywordsTopCategory?.sort((a, b) => (a?.distribution_percent < b?.distribution_percent) ? 1 : -1);
          this.keywordsTopCategory = {...sortedTopCategories};
        }
      }

      await this.fetchCompetitorsBundle();
    },
    minifyDigitsToLetters(val) {
      if (val >= 1e3 || val <= -1e3) {
        const units = ["K", "M", "B", "T"];
        let unit = Math.floor(((val).toFixed(0).length - 1) / 3) * 3
        let num = (val / ('1e' + unit)).toFixed(1)
        let unitName = units[Math.floor(unit / 3) - 1]
        return Math.abs(num) + unitName;
      }

      return Math.abs(val);
    },
    async addAllKeywordsHandler(addedKeywords) {
      const currentAppId = getCookie('currentAppId');
      let addedKeywordsArray = addedKeywords ?? [];
      let formData = new FormData();
      let tempKeywordsArray = [];
      let keywordsFormDataArray = new Set();

      if (!currentAppId) {
        return;
      }

      formData.append("country_code", this.currentCountry?.code);
      formData.append("application_id", currentAppId);
      tempKeywordsArray = [...this.autoSuggestedKeywords];

      for (const key in tempKeywordsArray) {
        addedKeywordsArray.forEach(keyword => {
          if (keyword?.keyword === tempKeywordsArray[key]?.keyword) {
            keywordsFormDataArray.add(tempKeywordsArray[key].keyword);
          }
        });
      }

      let counter = 0;
      keywordsFormDataArray.forEach(keyword => {
        formData.append(`keywords[${counter}]`, keyword);
        counter++;
      });

      let result = await httpRequest('POST', process.env.VUE_APP_API_URL + this.$ApiUrls.keywordsTracking.ALL_KEYWORDS, formData);

      if (result?.message === 'success') {

        tempKeywordsArray = [...this.autoSuggestedKeywords];

        for (const key in tempKeywordsArray) {
          addedKeywordsArray.forEach(keyword => {
            if (keyword?.keyword === tempKeywordsArray[key]?.keyword) {
              tempKeywordsArray[key].is_included = true;
            }
          });
        }

        this.autoSuggestedKeywords = [...tempKeywordsArray];
        this.$store.dispatch('UPDATE_USER_LIMITS');
        await this.fetchDataCounts();
      }
    },
    async addSingleKeywordHandler(addedKeywordArray) {
      await this.addAllKeywordsHandler(addedKeywordArray);
    },
    async fetchDataCounts() {
      this.$store.dispatch('SET_DATA_COUNTS_LOADER', false);
      await this.$store.dispatch('SET_DATA_COUNTS');
      this.$store.dispatch('SET_DATA_COUNTS_LOADER', true);
    },
    async modalCompetitorChanged() {
      await this.fetchCompetitorsBundle();

      this.$store.dispatch('UPDATE_USER_LIMITS');
      this.$store.dispatch('keywordsTracking/SET_LIVE_SEARCH_COMPETITORS_RESULTS');
    },
    async competitorsItemClickHandler(item) {
      this.clickedItem = {
        id: item.id,
        title: item.title,
        store: item?.store_key,
      };

      this.showAppDetailsModal = true;
    },
    async fetchLiveSearchResults(mode = 'increment', code) {
      this.liveSearchSkeletonShown = true;
      const countryCode = code ?? this.currentCountry?.code;

      await this.setAppsList();

      this.liveSearchDevice = this.currentApp?.store?.key !== 'APP_STORE' ? 'mobile' : this.liveSearchDevice;

      const url = process.env.VUE_APP_API_URL
          + this.$ApiUrls.keywordsTracking.APPLICATIONS_LIVE_SEARCH
          + '?country_code=' + countryCode
          + '&phrase=' + encodeURIComponent(this.currentKeyword?.toLowerCase()) + '&device=' + this.liveSearchDevice.toLowerCase()
          + '&store_key=' + this.currentApp?.store?.key
          + '&fields=id,store_key,logo,title,rating_num,votes_num,genre,developer_name&page=1';

      const result = await httpRequest('GET', url);

      let concatedArrays;
      let hasScreenshots = false;
      let hasNexPage;
      let resultArrays = [];

      if (!result?.results) {
        resultArrays = this.liveSearchResults?.results;
        hasNexPage = false;
      } else {
        concatedArrays = result?.results;
        resultArrays = this.liveSearchResults?.results ? [...this.liveSearchResults.results, ...result?.results] : result?.results;
        hasNexPage = result?.hasNexPage;
      }

      this.hasError = result.has_error;

      if (mode === 'increment') {
        concatedArrays = [...resultArrays];
      } else {
        this.liveSearchSkeletonShown = false;
      }

      concatedArrays?.forEach(item => {
        if (item?.data?.screenshots?.length > 0) {
          hasScreenshots = true;
        }
      });

      const tempLiveSearchResults = {
        hasNexPage,
        querySugg: result?.query_sugg,
        results: concatedArrays,
        hasScreenshots,
      };

      this.$store.dispatch('keywordsTracking/SET_LIVE_SEARCH_RESULTS', tempLiveSearchResults);
      this.$store.dispatch('keywordsTracking/SET_LIVE_SEARCH_COMPETITORS_RESULTS');
      this.liveSearchSkeletonShown = false;
    },
    async setAppsList() {
      // const result = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.myApps.RECENTLY_USED_APPLICATIONS);
      const result = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.myApps.MY_APPLICATIONS_NEW);
      this.$store.dispatch('SET_MY_APPS', UserAppsArrayCreator(result) ?? []);
    },
    async fetchCompetitorsBundle() {
      await competitorState.fetchCompetitors();
    },
    async competitorChanged(newType, id, oldType = null) {
      let competitorsCurrAmount = await competitorState.competitorChanged(
           newType,
           id,
           oldType,
           this.currentCountry?.id,
      );

      if (competitorsCurrAmount !== false) {
        let newAmount = +this.keywordsAverageMetrics.competitors + competitorsCurrAmount;
        newAmount = Math.max(newAmount, 0)
        this.keywordsAverageMetrics.competitors = newAmount;
        this.keywordsAverageMetrics = {...this.keywordsAverageMetrics};

        this.metricsData.competitors = newAmount;
        this.metricsData = {...this.metricsData};
      }
    },
    async liveAppClickHandler(app) {
      if (app.mode === 'remove') {
        this.showDeleteAppModal = true;
        this.appIdToDelete = {...app};
      } else {
        let formData = new FormData();
        formData.append('application_id', app.application_id);
        formData.append('country_code', this.currentCountry?.code);
        await httpRequest('POST', process.env.VUE_APP_API_URL + this.$ApiUrls.myApps.MY_APPLICATIONS, formData);

        const result = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.myApps.MY_APPLICATIONS);
        this.$store.dispatch('SET_MY_APPS', {...result?.applications} ?? {});
        this.$store.dispatch('keywordsTracking/SET_LIVE_SEARCH_ACTIVE_APPS', {id: app.application_id, mode: app.mode});
        this.$store.dispatch('UPDATE_USER_LIMITS');
      }
    },
    async deleteAppById() {
      let formData = new FormData();
      formData.append('_method', 'delete');
      await httpRequest('DELETE', process.env.VUE_APP_API_URL + this.$ApiUrls.myApps.MY_APPLICATIONS + '/' + this.appIdToDelete.application_id);

      this.closeModal('showDeleteAppModal');

      const result = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.myApps.MY_APPLICATIONS);
      this.$store.dispatch('SET_MY_APPS', {...result?.applications} ?? {});
      this.$store.dispatch('keywordsTracking/SET_LIVE_SEARCH_ACTIVE_APPS', {
        id: this.appIdToDelete.application_id,
        mode: this.appIdToDelete.mode
      });
    },
    detailsModalClosed() {
      this.showAppDetailsModal = !this.showAppDetailsModal;
    },
    async changeDevice(device) {
      this.liveSearchDevice = device;
      this.skeletonBoxesShown = true;
      this.liveSearchSkeletonShown = true;
      await this.fetchLiveSearchResults('reset');
      await this.fetchDataBundle();
    },
    async screenShotsClicked() {
      this.showScreenshotsBlock = !this.showScreenshotsBlock;
      this.screenshotsDataLoaded = false;
      await this.fetchAppScreenshotsData();
      this.screenshotsDataLoaded = true;
    },
    async fetchAppScreenshotsData(appId) {
      const currAppId = appId ?? this.currentApp?.id;
      const screensUrl = '?country_code=' + this.currentCountry?.code + '&app_id=' + currAppId + '&locale_code=' + 'en';
      const appScreenshotsData = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.competirorsFinder.APP_SCREENSHOTS + screensUrl);

      if (JSON.stringify(appScreenshotsData) !== '{}' && appScreenshotsData?.list?.length > 0) {
        this.appScreenshotsData = [...appScreenshotsData?.list];
      }
    },
    async translate() {
      let phrases = this.liveSearchResults?.results
        .filter(item => item.data.translate === undefined || item.data.translate === null)
        .map(item => item.data.title);
      let translations = await translates.translatePhrases(this.currentApp?.store?.key, phrases);
      this.liveSearchResults?.results.forEach((item) => {
        item.data.translate = translations[item.data.title] ?? null;
      });
    },
    async translateClickHandler() {
      if (this.translateIsActive) {
        this.liveSearchResults?.results.forEach((item) => {
          item.data.translate = null;
        });
        this.translateIsActive = false;
        return;
      }

      await this.translate();
      this.translateIsActive = true;
    },
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'pageMeta',
      'currentApp',
      'promiseSettledData',
      'currentCountry',
      'currentCountryManual',
    ]),
    ...mapGetters('keywordsTracking', [
      'popularityChartResults',
      'suggestedKeywords',
      'liveSearchResults',
      'competitorsResults',
    ]),
    popularityChartResultsLength() {
      return Object.values(this.popularityChartResults)?.length;
    },
    noAppsLabel() {
      const storeName = this.currentApp?.store?.key === "APP_STORE" ? "App store" : "Google Play"
      return `No apps in ${storeName} for this keywords...`
    }
  },
  watch: {
    liveSearchResults(newVal, oldVal) {
      let isIdsEqual = JSON.stringify(newVal?.results?.map(item => item.data.id)) === JSON.stringify(oldVal?.results?.map(item => item.data.id));
      if (this.liveSearchResults?.results?.length > 0 && this.translateIsActive && !isIdsEqual) {
        this.translate();
      }
    },
    async updateExploreComponent(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.suggestsSkeletonBoxesShown = true;
        this.skeletonBoxesShown = true;
        this.liveSearchSkeletonShown = true;
        await this.fetchLiveSearchResults('reset');
        await this.fetchDataBundle();
      }
    },
    async currentApp(newVal, oldVal) {
      if (newVal?.id !== oldVal?.id && oldVal?.id) {
        this.suggestsSkeletonBoxesShown = true;
        this.skeletonBoxesShown = true;
        this.liveSearchSkeletonShown = true;

        if (newVal?.store?.key === 'APP_STORE') {
          this.liveSearchDevice = 'iphone'
        }
        await this.fetchLiveSearchResults('reset');
        await this.fetchDataBundle();
      }
    },
    async currentCountryManual(newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        this.suggestsSkeletonBoxesShown = true;
        this.skeletonBoxesShown = true;
        this.liveSearchSkeletonShown = true;
        await this.fetchLiveSearchResults('reset', newVal?.code);
        await this.fetchDataBundle(newVal?.code);
      }
    },
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>