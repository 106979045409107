<script>

import ModalAppMetadata from "@/views/CompetitorsFinderPage/components/ModalAppMetadata/index.vue";
import PreloaderTableSmall from "@/components/UI/PreloaderTableSmall/index.vue";
import {mapGetters} from "vuex";

export default {
  name: "TextMetadata",
  components: {
    'modal-app-metadata': ModalAppMetadata,
    'preloader-table-small': PreloaderTableSmall
  },
  computed: {
    ...mapGetters([
      'promiseSettledData',
      'currentCountry',
    ]),
  },
  props: {
    appData: {
      type: Object,
    },
    filterLocalesArray: {
      type: Array,
    },
    filterCountriesArray: {
      type: Array
    },
  },
  data() {
    return {
      showAllMetadata: false,
      metadataLoaded: false,
      currentCompetitorStore: '',
      modalData: {
        topAppData: {},
        appMetaData: [],
      }
    }
  },
  mounted() {
    this.currentCompetitorStore = this.appData?.store;
    this.fetchMetadataPromisesBundle();
  },
  methods: {
    async fetchMetadataPromisesBundle() {
      const url = '?country_code=' + this.currentCountry?.code + '&app_id=' + this.appData?.id;
      this.metadataLoaded = false;
      const promiseUrls = [
        {
          url: process.env.VUE_APP_API_URL + this.$ApiUrls.competirorsFinder.TEXTS_BLOCK + url,
          method: 'GET',
        }
      ];

      await this.$store.dispatch('FETCH_PROMISE_ALL_SETTLED', promiseUrls);
      const appMetaData = this.promiseSettledData?.data[0]?.value;

      if (this.promiseSettledData.success) {
        if (JSON.stringify(appMetaData) !== '{}' && !appMetaData.hasOwnProperty('errors')) {
          this.modalData.appMetaData = this.constructLocalizationRows(appMetaData, this.filterLocalesArray);
        }
      }

      this.metadataLoaded = true;
    },
    calculateShowMetaDataItem(idx) {
      if (!idx && idx !== 0) {
        return false;
      }
      if (this.showAllMetadata) {
        return true;
      }

      return !this.showAllMetadata && idx < 4;
    },
    showAllMetadataClicked() {
      this.showAllMetadata = !this.showAllMetadata;
    },
    constructLocalizationRows(localizationsObj, fullLocalesArray) {
      if (!localizationsObj) {
        return;
      }
      const titles = [...localizationsObj?.titles];
      const subTitles = [...localizationsObj?.sub_titles];
      const descriptions = [...localizationsObj?.descriptions];
      let summaryLocales = [];
      let localeRows = [];
      let unitedBundles = [];

      titles.forEach(title => {
        summaryLocales = [...summaryLocales, ...title.locales];
      });

      summaryLocales.forEach(locale => {
        const matchTitle = titles.filter(title => title.locales.includes(locale));
        const matchSubtitle = subTitles.filter(subtitle => subtitle.locales.includes(locale));
        const matchDescription = descriptions.filter(description => description.locales.includes(locale));

        localeRows.push({
          title: matchTitle[0]?.text,
          subTitle: matchSubtitle[0]?.text,
          description: matchDescription[0]?.text,
          locale: locale,
        });
      });

      let matchElement = {};

      localeRows.forEach(locale => {
        const findedMatchIdx = unitedBundles.findIndex(item => item.title === locale.title && item.subTitle === locale.subTitle);
        if (findedMatchIdx < 0) {
          matchElement = {...locale};
          let bundle = [];
          let localeArray = [];

          localeRows.forEach(innerLocale => {
            if (innerLocale.title === matchElement.title && innerLocale.subTitle === matchElement.subTitle) {
              bundle.push(innerLocale);
              localeArray.push(innerLocale.locale);
            }
          });

          let groupedBundles = [];

          bundle.forEach(item => {
            const itemIdx = groupedBundles.findIndex(bundleItem => bundleItem.description === item.description);

            if (itemIdx < 0) {
              groupedBundles.push({
                title: item.title,
                subTitle: item.subTitle,
                description: item.description,
                locale: item.locale,
              });
            } else {
              let groupedBundlesCopy = [...groupedBundles];
              groupedBundlesCopy[itemIdx].locale += ',' + item.locale;
              groupedBundles = [...groupedBundlesCopy];
            }

          });

          const bundleFormattedGroup = groupedBundles.map(bundleItem => {
            let tempItem = {...bundleItem};
            const bundleLocalesArray = tempItem.locale.split(',');
            tempItem.localesString = this.constructLocalesString(fullLocalesArray, bundleLocalesArray);
            tempItem.localesAmount = bundleLocalesArray.length;

            return tempItem;
          });

          const localesString = this.constructLocalesString(fullLocalesArray, localeArray);

          unitedBundles.push({
            title: bundle[0]?.title,
            subTitle: bundle[0]?.subTitle,
            description: bundle[0]?.description,
            descriptionArray: bundleFormattedGroup,
            locales: localeArray,
            localesString: localesString
          });
        }
      });

      return unitedBundles;
    },
    constructLocalesString(fullLocalesArray, localeArray) {
      let localeString = '';
      localeArray.forEach((locale, idx) => {
        let sign = '';
        if (idx !== 0) {
          sign = ', ';
        }
        const splittedCode = locale.split('_');
        let countryExtention = '';

        if (splittedCode?.length > 1) {
          countryExtention = ' (' + this.decodeLocaleItem(this.filterCountriesArray, splittedCode[1]) + ')';
        }
        localeString += sign + this.decodeLocaleItem(fullLocalesArray, locale) + countryExtention;
      });

      return localeString;
    },
    decodeLocaleItem(fullLocalesArray, code) {
      let localeString = '';
      const localeFullData = fullLocalesArray.find(item => item?.code?.toLowerCase() === code?.toLowerCase());
      if (localeFullData) {
        return localeString = localeFullData?.name;
      } else {
        return code;
      }
    }
  },
  watch: {
    appData() {
      this.fetchMetadataPromisesBundle()
    }
  }
}
</script>

<template>
  <div class="tab-component-title">Text Metadata</div>
  <div class="metadata-block">
    <template v-if="metadataLoaded">
      <div class="metadata-content-block">

        <template v-for="(item, idx) in modalData.appMetaData">
          <template v-if="calculateShowMetaDataItem(idx)">
            <modal-app-metadata :with-header="idx === 0"
                                 :app-logo="modalData.topAppData?.app?.logo"
                                 :app-store="currentCompetitorStore"
                                 :data="item"/>
          </template>
        </template>
      </div>

      <div class="show-all-block"
           v-if="modalData.appMetaData?.length > 3">
        <div class="show-all"
             @click="showAllMetadataClicked">
          <span class="text">
            <template v-if="!showAllMetadata">Show All</template>
            <template v-else>Show Less</template>
          </span>
          <svg-icon :class="{reverted: showAllMetadata}" icon="arrow-down-icon"/>

        </div>
      </div>
    </template>
    <div class="preloader-wrap"
         v-else>
      <preloader-table-small loader-size="50px"></preloader-table-small>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>