<script>

import ModalAppMetrics from "@/views/CompetitorsFinderPage/components/ModalAppMetrics/index.vue";
import PreloaderTableSmall from "@/components/UI/PreloaderTableSmall/index.vue";
import {httpRequest} from "@/api";
import {convertDays, formatDate} from "@/utils/utils";
import {mapGetters} from "vuex";

export default {
  name: "MetricsData",
  components: {
    'modal-app-metrics': ModalAppMetrics,
    'preloader-table-small': PreloaderTableSmall
  },
  computed: {
    ...mapGetters([
      'promiseSettledData',
      'currentCountry',
    ]),
  },
  props: {
    appData: {
      type: Object,
    },
  },
  mounted() {
    this.fetchOldAppMetricsData();
  },
  data() {
    return {
      metricsDataLoaded: false,
      modalData: {
        appMetricsData: {},
      }
    }
  },
  methods: {
    async fetchOldAppMetricsData() {
      const url = '?country_code=' + this.currentCountry?.code + '&app_id=' + this.appData?.id;
      this.metricsDataLoaded = false;
      const appMetricsData = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.competirorsFinder.APP_METRICS_BLOCK + url);

      if (JSON.stringify(appMetricsData) !== '{}') {
        const iap_from = appMetricsData?.IAP?.from;
        const iap_to = appMetricsData?.IAP?.to;
        this.modalData.appMetricsData = {
          update_frequency_days: appMetricsData?.update_frequency_days ? convertDays(appMetricsData?.update_frequency_days) : '-',
          age: appMetricsData?.age ? convertDays(appMetricsData?.age) : '',
          update_date: appMetricsData?.update_date ? formatDate(new Date(appMetricsData?.update_date), 'month-day-year') : '',
          iap_from: iap_from?.toLocaleString('ru-RU', {maximumFractionDigits: 0}),
          iap_to: iap_to?.toLocaleString('ru-RU', {maximumFractionDigits: 0}),
          iap_sign: appMetricsData?.iap_sign ?? '$',
        };
      }

      this.metricsDataLoaded = true;
    }
  },
  watch: {
    appData() {
      this.fetchOldAppMetricsData();
    }
  }
}
</script>

<template>
  <modal-app-metrics :is-metrics-data-loaded="metricsDataLoaded"
                     classes="bottom">
    <template v-slot:metrics-items>
      <div class="metrics-grid-wrap">
        <div class="metrics-grid-item">
          <div class="metrics-icon">
            <svg-icon icon="metrics-update"/>
          </div>
          <div class="metric-value">{{ modalData.appMetricsData?.update_frequency_days ?? '-' }}</div>
          <div class="metrics-title">Update Frequency</div>
        </div>
      </div>
      <div class="metrics-grid-wrap">
        <div class="metrics-grid-item">
          <div class="metrics-icon">
            <svg-icon icon="metrics-last-update"/>
          </div>
          <div class="metric-value">{{ modalData.appMetricsData?.update_date ?? '' }}</div>
          <div class="metrics-title">Last Update</div>
        </div>
      </div>
      <div class="metrics-grid-wrap">
        <div class="metrics-grid-item">
          <div class="metrics-icon">
            <svg-icon icon="metrics-app-age"/>
          </div>
          <div class="metric-value">{{ modalData.appMetricsData?.age ?? '' }}</div>
          <div class="metrics-title">App Age</div>
        </div>
      </div>
      <div class="metrics-grid-wrap">
        <div class="metrics-grid-item">
          <div class="metrics-icon">
            <svg-icon icon="metrics-iap"/>
          </div>
          <template v-if="modalData.appMetricsData?.iap_from !== '' && modalData.appMetricsData?.iap_to !== ''">
            <div class="metric-value">
              <template
                  v-if="modalData.appMetricsData?.iap_from !== '0' && modalData.appMetricsData?.iap_to !== '0'">
                {{ modalData.appMetricsData?.iap_sign }}{{ modalData.appMetricsData?.iap_from }} -
                {{ modalData.appMetricsData?.iap_sign }}{{ modalData.appMetricsData?.iap_to }}
              </template>
              <template v-else>
                -
              </template>
            </div>
          </template>
          <div class="metrics-title">iAP</div>
        </div>
      </div>
    </template>

    <template v-slot:preloader-block>
      <div class="preloader-wrap">
        <preloader-table-small loader-size="50px"></preloader-table-small>
      </div>
    </template>
  </modal-app-metrics>
</template>

<style scoped lang="scss">

</style>