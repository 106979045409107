<script>
import ExpandRankTableChart from "../../../components/DataTables/Tools/ExpandRankTableChart/ExpandRankTableChart.vue";

export default {
  name: "RankExpandWrap",
  components: {ExpandRankTableChart},
  props: {
    app: {},
    country: {},
    keyword: {},
  },
}
</script>

<template>
<div class="common-white-container">
  <expand-rank-table-chart :app="app" :country="country" :keyword="keyword" :has-close-btn="false" :additional-titles="true"></expand-rank-table-chart>
</div>
</template>

<style scoped lang="scss">

</style>