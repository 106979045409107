<script>
import AppRankTool from "@/components/DataTables/Tools/AppRankTool/index.vue";
import RatingTool from "@/components/DataTables/Tools/RatingTool/index.vue";
import {cropImage, formatDate} from "@/utils/utils";
import {defineComponent,createApp} from 'vue'
import DatepickerWithRange from "@/components/UI/DatepickerWithRange/index.vue";
import currentApp from "../../../UI/CurrentApp/index.vue";
import Tooltip from "../../../UI/Tooltip/index.vue";

export default {
  name: "ChartBlock",
  computed: {
    currentApp() {
      return currentApp
    }
  },
  components: {Tooltip, AppRankTool, RatingTool, DatepickerWithRange},
  props: {
    app: {},
    keyword: {},
    chartRawData: {},

    showAppTitle: {
      type: Boolean,
      default: false
    },
    withoutBorder: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      options: {},
      chartLoaded: false,
    }
  },
  mounted() {
    let x = [];
    let y = [];

    this.chartRawData.forEach((datum) => {
      let date = Date.parse(datum[0])
      x.push(date);

      if (datum[1].length === 0) {
        y.push({
          y: y[y.length - 1] ?? null,
          color: 'gray',
          marker: {
            enabled: false
          },
        });
        return;
      };

      let appVal = null;
      datum[1].forEach((value) => {
        if (value.app.id === this.app.id) appVal = value;
      });

      let custom = {
        ctx: {
          createApp,
          defineComponent,
          adaptiveTopDataForTools: this.adaptiveTopDataForTools,
        },
        keyword: this.keyword,
        currentAppId: this.app.id,
        positions: datum[1],
        store_key: this.app.store.key
      };

      if (appVal) y.push({
        y: appVal.position.value,
        custom: custom
      });
      else y.push({
        y: 51,
        color: 'red',
        marker: {
          fillColor: 'red',
          lineColor: 'red',
          radius: 2
        },
        custom: custom
      });
    });

    this.options = {
      "chart": {
        "type": "spline",
        "height": 450,

        'scrollablePlotArea': {
          'minWidth': 800,
          'scrollPositionX': 1
        },
      },
      "title": false,
      "xAxis": {
        reversed: true,
        "categories": x,
        "title": false,
        'type': 'datetime',
        // tickInterval: 24 * 3600 * 1000,
        "labels": {
          "style": {"color": "#505660", "fontWeight": "400", "fontSize": "14px"},
          rotation: -90,
          format: '{value:%b %d}',
        },
        "gridLineDashStyle": "solid",
        "gridLineColor": "#e5e8eb",
        "gridLineWidth": 1,
        "showLine": true,
        "lineWidth": 1,
        "lineColor": "#e5e8eb",
        "offset": 12
      },
      "yAxis": {
        "title": false,
        reversed: true,
        "labels": {
          "style": {"color": "#505660", "fontWeight": "400", "fontSize": "14px"},
          formatter: function () {
            if (this.pos >= 51) {
              return 'Out';
            } else {
              return this.pos
            }
          },
        },
        tickInterval: 1,
        "gridLineDashStyle": "solid",
        "gridLineColor": "#e5e8eb",
        "gridLineWidth": 1,
        "showLine": true,
        "lineWidth": 1,
        "lineColor": "#e5e8eb"
      },
      "series": [{
        "name": "Rank",
        "data": y,
        "lineWidth": 2,
        "pointHitRadius": 10,
        "color": "#5d71fa",
        "marker": {
          "radius": 1,
          "shape": "circle",
          "symbol": "circle",
          "fillColor": "#FFFFFF",
          "lineWidth": 1,
          "lineColor": null,
          "states": {
            "hover": {
              "fillColor": "white",
              "radius": 5,
              "lineWidth": 2,
              "lineColor": "#5d71fa",
              "animation": {"duration": 500}
            }
          }
        },
        "states": {
          "inactive": {"opacity": 1},
          "hover": {
            "halo": {
              "size": 4,
              "attributes": {"fill": "transparent", "stroke-width": 2, "stroke": "transparent"}
            }, "lineWidth": 2
          }
        },
        "pointPlacement": "on",
        "dataLabels": {"enabled": false},
        "animation": false
      }],
      "options": {
        "scales": {
          "xAxes": [{"gridLines": {"display": false}}],
          "yAxes": [{
            "stacked": true,
            "gridLines": {
              "borderDash": [8, 4],
              "color": "#f0f3fd",
              "zeroLineColor": "transparent",
              "drawBorder": false,
              "drawOnChartArea": true
            },
            "type": "linear"
          }]
        }
      },
      "tooltip": {
        enabled: true,
        "distance": 10,
        "padding": 0,
        "outside": false,
        "useHTML": true,
        "backgroundColor": "#fff",
        "borderWidth": 0,
        "borderRadius": 4,
        "shadow": false,
        "shape": "square",
        "hideDelay": 100,
        "shared": true,
        "split": false,
        style: {
          pointerEvents: 'auto'
        },
        formatter: function () {
          let custom = this.points[0].point.custom;

          let html = `<div class="tooltip-inner-container top-history-container">`;
          html += `<div class="tooltip-inner-header">${formatDate(this.x, 'month-day-year')}</div>`;
          html += `<table>`;
          html += `<tbody>`;

          let positions = custom.positions.slice(0, 10);
          let hasMyApp = false;
          positions.forEach((value) => {
            if (value.app.id === custom.currentAppId) hasMyApp = true;
          });
          if (!hasMyApp) {
            custom.positions.forEach((value) => {
              if (value.app.id === custom.currentAppId) {
                positions.push(value);
              }
            });
          }

          positions.forEach((value) => {
            let rankComp = custom.ctx.defineComponent({
              extends: AppRankTool
            });
            let ratingComp = custom.ctx.defineComponent({
              extends: RatingTool
            });

            let rankTD = document.createElement('td');
            let ratingTD = document.createElement('td');

            custom.ctx.createApp(rankComp, {
              item: custom.ctx.adaptiveTopDataForTools(value.position)
            }).mount(rankTD);
            custom.ctx.createApp(ratingComp, {
              rating: Number((value.app.rating?.toFixed(1) ?? 0))
            }).mount(ratingTD);

            if (value.app.id === custom.currentAppId) {
              html += `<tr class="my-app-row">`;
            } else {
              html += `<tr>`;
            }
            html += `<td class="rank-col"><div>${rankTD.innerHTML}</div></td>`;
            html += `<td><div class="logo-col"><img height="24" width="24" src="${cropImage(value.app.logo, '24x24', custom.store_key)}"></div></td>`;
            if (value.app.title) {
              html += `<td><div class="rating-col">${value.app.title}</div></td>`;
            } else {
              html += `<td><div class="rating-col story-col color-primary">Non-app Item</div></td>`;
            }
            if (value.app.rating) {
              html += `<td><div class="rating-col">${ratingTD.innerHTML}</div></td>`;
            } else {
              html += `<td><div class="rating-col"></div></td>`;
            }
            html += `</tr>`;
          });
          html += `</tbody>`;
          html += `</table>`;
          html += `</div>`;

          return html;
        }
      },
      "legend": {
        "enabled": false,
        "align": "left",
        "verticalAlign": "top",
        "itemStyle": {"color": "#505660", "fontWeight": "400", "fontSize": "14px"},
        "margin": 24
      }
    };
    this.chartLoaded = true;
  },
  methods: {
    cropImage,
    adaptiveTopDataForTools(rank) {
      return {
        difference: rank.dynamic,
        rank: rank.value,
      };
    }
  }
}
</script>

<template>
  <div class="chart_container" :style="withoutBorder ? {'padding': 0, 'border': 'none'} : {}">
    <div class="chart_nav">
      <div class="chart_title display-flex" v-if="showAppTitle">
        <tooltip width="max-content">
          <template v-slot:slotTrigger>
            <div class="display-flex">
              <img class="mr-10" height="24" width="24" :src="cropImage(app.logo, '24x24', app.store.key)">
            </div>
          </template>
          <template v-slot:slotContent>{{app.title}}</template>
        </tooltip>
        Ranking History
      </div>
      <div class="chart_title" v-else>Ranking History</div>
      <div>
        <slot name="datepicker"></slot>
      </div>
    </div>
    <vue-highcharts
        v-if="chartLoaded"
        type="chart"
        :options="options"
        :redrawOnUpdate="true"
        :oneToOneUpdate="false"
        :animateOnUpdate="true"/>
  </div>
</template>

<style scoped lang="scss">
.chart_container {
  padding: 20px;
  border: 1px solid var(--neutral-400);
  border-radius: 8px;

  .chart_nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 0 10px;
  }
}
</style>
<style lang="scss">
.tooltip-inner-container.top-history-container {
  box-shadow: 0px 4px 8px 0px #A0BEDD4D;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;

  .tooltip-inner-header {
    color: var(--neutral-800);
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;

    padding-left: 15px;
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px solid var(--neutral-400);
  }

  table {
    border: none;
    border-spacing: 0;

    color: var(--neutral-800);
    font-size: 15px;
    font-weight: 400;

    tr.my-app-row {
      background-color: var(--neutral-300);
    }

    td {
      padding: 5px 5px;
      border-bottom: 1px solid var(--neutral-400);
    }
    tr:last-of-type td {
      border-bottom: none;
    }
    tr {
      td {
        &:first-of-type {
          padding-left: 10px;
        }
        &:last-of-type {
          padding-right: 10px;
        }
      }
    }

    .rank-col > div {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      text-align: right;
      min-width: 37px;

      .rank-difference {
        display: inline-flex;
        align-items: center;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.43;
        margin-right: 4px;
      }
      .value-block {
        min-width: 19px;
      }
      .spinner-wrap {
        display: flex;
        align-items: center;
        .rank-difference {
          margin-left: 12px;
        }
      }
    }

    .rating-col {
      padding-right: 30px;

      .rating-icon {
        margin-right: 5px;
      }

      &.story-col {
        margin-left: -30px;
      }
    }

    .logo-col {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>