<template>
  <custom-modal :opened="opened"
                :max-width="1110"
                class-name="competitors-finder-modal"
                :transparent-bg="transparentBg"
                @closeModal="closeModalHandler('showAppDetailsModal')">
    <div class="modal-message">

      <app-details-component :app-data="appData"
                             :can-select-competitors="canSelectCompetitors"
                             @competitor-changed="modalCompetitorChanged"
                             :update-explore-component="updateExploreComponent"
                             :change-competitor-in="true"/>
    </div>
  </custom-modal>
</template>

<script>
import KeywordExplorerComponent from "@/components/KeywordExplorerComponent/index.vue";
import AppDetailsComponent from "@/components/AppDetailsComponent/index.vue";

export default {
  name: "DetailsModal",
  components: {
    'keyword-explorer-component': KeywordExplorerComponent,
    AppDetailsComponent
  },
  emits: ['modal-closed', 'competitor-changed'],
  props: {
    opened: {
      type: Boolean,
    },
    transparentBg: {
      type: Boolean,
      default: false
    },
    appData: {
      type: Object,
    },
    canSelectCompetitors: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      updateExploreComponent: 0,
    }
  },
  methods: {
    closeModalHandler(modalName) {
      this.$emit('modal-closed', modalName);
      this.closeModal(modalName);
    },
    modalCompetitorChanged(e) {
      this.$emit('competitor-changed', e);
    }
  },
}
</script>

<style scoped>

</style>