<template>
  <div class="app-search-billet-wrap">
    <div class="app-billet">

      <div class="billet-content">
        <template v-if="alternativePositionPlace">
          <div class="position mobile-hidden alternative-position"><span v-if="!itemData.is_paid">#{{ itemIndex }}</span></div>
        </template>

        <div class="app-logo"
             :class="[{'cursor-pointer': itemData?.type === 'apps', 'custom-css-tooltip': itemData?.type === 'apps'}]"
             data-title="Open detailed info"
             v-on="itemData?.type === 'apps' ? {click: () => appDetailsClicked(itemData?.data)}: {}">
          <template v-if="itemData?.data?.logo">
            <app-image
             v-if="itemData?.type === 'editorial-items' || itemData?.type === 'subscription' || itemData?.type === 'in-apps' || itemData?.type === 'app-bundles'"
                  :width="50"
                  :height="50"
                  :lazy-src="itemData?.data?.logo"
            />
            <app-image v-else
                  :width="50"
                  :height="50"
                  :lazy-src="cropImg(itemData?.data?.logo, '50x50', itemData?.data?.store_key)"
            />
          </template>
          <template v-else>
            <div class="empty-logo"></div>
          </template>
        </div>

        <div class="app-info"
             :class="{'alternative-position': alternativePositionPlace}">
          <div class="app-details-top">
            <div class="position mobile-hidden"
                 v-if="!alternativePositionPlace && !itemData.is_paid">
              <span>#{{ itemIndex }}</span>
            </div>
            <a class="app-link mobile-hidden"
               v-if="itemData?.data?.link"
               :href="itemData.data.link"
               target="_blank">
              <svg-icon icon="link-icon" class="link-icon"/>
            </a>
            <div class="app-name display-flex"
                 :class="[{'cursor-pointer': itemData?.type === 'apps', 'custom-css-tooltip': itemData?.type === 'apps'}]"
                 data-title="Open detailed info"
                 v-on="itemData?.type === 'apps' ? {click: () => appDetailsClicked(itemData?.data)}: {}">
              {{ itemData?.data?.title }} <div class="ads_mark ml-10" v-if="itemData.is_paid">Ad</div>
            </div>
          </div>
          <div class="translated-block">
            {{ itemData?.data?.translate }}
          </div>
          <div class="app-details-bottom mobile-hidden">
            <div class="rating"
                 v-if="itemData?.type === 'apps' || itemData?.type === 'in-apps'">
              <svg-icon icon="star" class="rating-icon"/>
              <div class="rating-amount">
                {{ itemData?.data?.rating_num }}
              </div>
            </div>
            <div class="app-type"
                 v-if="itemData?.type !== 'apps'">{{ itemData?.typeFormatted }}
            </div>
            <template v-if="itemData?.type === 'apps' || itemData?.type === 'in-apps'">
              <div class="separator">/</div>
              <div class="rank-amount">
                {{ formatVotesNumber(itemData?.data?.votes_num) }} Ratings
              </div>
              <div class="separator">/</div>
              <div class="app-category">{{ itemData?.data?.developer_name }}</div>
            </template>
          </div>

          <div class="actions">
            <div class="add-app-block"
                 v-if="itemData?.type === 'subscription' || itemData?.type === 'in-apps'">
              <div class="price"
                   v-if="itemData?.data?.price">${{ itemData?.data?.price }}
              </div>
            </div>

            <a class="app-link mobile-visible"
               v-if="itemData?.data?.link"
               :href="itemData.data.link"
               target="_blank">
              <svg-icon icon="link-icon" class="link-icon"/>
            </a>

            <div class="competitors-block mobile-hidden"
                 v-if="+currentApp?.id !== +itemData?.data?.id && itemData?.type === 'apps'">

              <change-competitor-state :item="itemData"
                                       :current-app-id="currentApp?.id"
                                       @competitor-changed="competitorChanged"
              />
            </div>
          </div>
        </div>

        <div class="app-details-bottom mobile-visible">
          <div class="rating"
               v-if="itemData?.type === 'apps' || itemData?.type === 'in-apps'">
            <svg-icon icon="star" class="rating-icon"/>
            <div class="rating-amount">
              {{ itemData?.data?.rating_num }}
            </div>
          </div>
          <div class="app-type"
               v-if="itemData?.type !== 'apps'">{{ itemData?.typeFormatted }}
          </div>
          <template v-if="itemData?.type === 'apps' || itemData?.type === 'in-apps'">
            <div class="separator">/</div>
            <div class="rank-amount">
              {{ formatVotesNumber(itemData?.data?.votes_num) }} Ratings
            </div>
            <div class="separator">/</div>
            <div class="app-category">{{ itemData?.data?.developer_name }}</div>
          </template>
        </div>

        <div class="competitors-block mobile-visible"
             v-if="+currentApp?.id !== +itemData?.data?.id && itemData?.type === 'apps'">
          <change-competitor-state :item="itemData"
                                   mode="texts"
                                   :current-app-id="currentApp?.id"
                                   @competitor-changed="competitorChanged"
          />
        </div>
      </div>

      <template v-if="!noScreenshots">
        <div v-if="!showExtended && screenshotsComputed?.length > 0"
             class="billet-screenshots">
          <template v-for="screenshot in itemData?.data?.screenshots">
            <image-with-direction :src="screenshot?.src"
                                  :item-data="itemData"/>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {cropImage} from "@/utils/utils";
import ImageWithDirection from "@/components/Billets/ImageWithDirection/index.vue";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import ChangeCompetitorState from "@/components/Billets/ChangeCompetitorState/index.vue";

export default {
  name: "LiveSearchBillet",
  props: {
    itemData: {
      type: Object
    },
    showExtended: {},
    itemIndex: {
      type: Number
    },
    noScreenshots: {
      type: Boolean,
      default: false
    },
    alternativePositionPlace: {
      type: Boolean,
      default: false
    },
    currentApp: {
      type: Object
    }
  },
  components: {
    ChangeCompetitorState,
    SvgIcon,
    ImageWithDirection
  },
  emits: [
    'live-app-clicked',
    'open-app-modal-clicked',
    'competitor-changed',
  ],
  data() {
    return {
      screenshotsClass: '',
    }
  },
  methods: {
    competitorChanged(isCompetitor) {
      this.$emit('competitor-changed', isCompetitor, this.itemData.data.id, this.itemData.competitorType);
    },
    async addApp() {
      this.$emit('live-app-clicked', {application_id: this.itemData.data.id, mode: 'add'});
    },
    removeApp() {
      this.$emit('live-app-clicked', {application_id: this.itemData.data.id, mode: 'remove'});
    },
    minifyDigitsToLetters(val) {
      if (val >= 1e3 || val <= -1e3) {
        const units = ["K", "M", "B", "T"];
        let unit = Math.floor(((val).toFixed(0).length - 1) / 3) * 3
        let num = (val / ('1e' + unit)).toFixed(1)
        let unitName = units[Math.floor(unit / 3) - 1]
        return Math.abs(num) + unitName;
      }

      return Math.abs(val);
    },
    formatVotesNumber(item) {
      return item ? this.minifyDigitsToLetters(item) : null;
    },
    cropImg(imageUrl, size, store) {
      return cropImage(imageUrl, size, store);
    },
    appDetailsClicked(app) {
      this.$emit('open-app-modal-clicked', app);
    }
  },
  computed: {
    screenshotsComputed() {
      return this.itemData?.data?.screenshots.map(screenshot => {
        return {
          src: screenshot,
        }
      });
    },
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>