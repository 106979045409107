export const appInstallsRevenueTableConfig = {
    headers: [
        {
            text: 'Countries',
            value: 'country',
            align: 'left',
            width: '20%',
            textWidth: '250px',
            sortable: false,
            headingClass: 'country-col',
        },
        {
            text: 'Installs',
            value: 'installs',
            sortBy: 'installs',
            align: 'right',
            width: '20%',
            textWidth: '250px',
            sortable: true,
        },
        {
            text: '%',
            value: 'installs_percent',
            width: '20%',
            textWidth: '25%',
            align: 'right',
            sortable: false,
        },
        {
            text: 'Revenue, $',
            value: 'revenue',
            sortBy: 'revenue',
            width: '20%',
            textWidth: '25%',
            align: 'right',
            sortable: true,
        },
        {
            text: '%',
            value: 'revenue_percent',
            width: '20%',
            textWidth: '25%',
            align: 'right',
            sortable: false,
        },
    ],
}