<template>
  <div class="modal-details-upper-new">
    <div class="details-block">
      <div class="app-logo">
        <template v-if="data?.app?.logo && isLoaded">
          <img :src="cropImage(data?.app?.logo, '100x100', data?.app?.store)" :alt="data?.app?.title">
        </template>
        <template v-else>
          <div class="empty-logo"></div>
        </template>
      </div>

      <div class="app-details">
        <template v-if="isLoaded">
          <div class="modal-competitors-block mobile-visible" v-if="canSelectCompetitors">
            <change-competitor-state :item="{
                                         competitorType: currCompetitorType,
                                         data: {
                                           id: data?.app?.id
                                         }
                                       }"
                                     mode="texts"
                                     :current-app-id="currentApp?.id"
                                     @competitor-changed="competitorChanged"
            />
          </div>
          <div class="app-name">{{ data?.app?.title ?? '' }}</div>
          <div class="app-subtitle">
            <span class="subtitle-item">{{ data?.app?.developer?.name ?? '' }}</span>
            <span class="title-separator"></span>
            <span class="subtitle-item">{{ data?.app?.sub_title ? sanitizeString(data?.app?.sub_title) : '' }}</span>
<!--            <span class="title-separator"></span>-->
          </div>
          <div class="bottom-content">
            <div class="options-block">
              <a class="subtitle-link"
                 :href="data?.app?.url"
                 target="_blank">
                <svg-icon icon="inner-link-icon"/>
                <span>View in {{ data?.app?.store === 'APP_STORE' ? 'App Store' : 'Google Play' }}</span>
              </a>
              <template v-if="data?.isAdded">
                <div class="add-block added">
                  <svg-icon icon="check"
                            class="remove-icon active"/>
                  <span>App is added</span>
                </div>
              </template>
              <template v-else>
                <div class="add-block"
                     @click="addApp">
                  <svg-icon icon="plus-circle"/>
                  <span>Add to My Apps</span>
                </div>
              </template>
            </div>
            <div class="modal-competitors-block mobile-hidden" v-if="canSelectCompetitors">
              <change-competitor-state :item="{
                                         competitorType: currCompetitorType,
                                         data: {
                                           id: data?.app?.id
                                         }
                                       }"
                                       mode="texts"
                                       :current-app-id="currentApp?.id"
                                       @competitor-changed="competitorChanged"
              />
            </div>
          </div>
        </template>
        <template v-else>
          <div class="skeleton-wrap">
            <div class="skeleton long-skeleton-box"></div>
            <div class="skeleton medium-skeleton-box"></div>
            <div class="skeleton short-skeleton-box"></div>
          </div>
        </template>
      </div>
    </div>
    <div class="aso-score-block">
      <div class="aso-score"
           :class="[setAsoScoreColor(data?.score?.score)]">
        <template v-if="isLoaded">
          {{ data?.score?.score ?? 0 }}
        </template>
        <template v-else>
          <div class="skeleton aso-score"></div>
        </template>
      </div>
      <div class="text">ASO score</div>
    </div>
  </div>
</template>

<script>
import CustomDropdown from "@/components/UI/CustomDropdown";
import SvgIcon from "@/components/UI/SvgIcon";
import {ref, computed, watch} from 'vue';
import {cropImage, sanitizeString} from "@/utils/utils";
import ChangeCompetitorState from "@/components/Billets/ChangeCompetitorState/index.vue";
import {useStore} from "vuex";

export default {
  name: "ModalAppUpperDetails",
  props: {
    data: {
      type: Object,
    },
    isLoaded: {
      type: Boolean,
      default: false,
    },
    topLocalesData: {
      type: Array,
    },
    competitorType: {
      type: String,
    },
    competitorId: {
      type: Number,
    },
    canSelectCompetitors: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    ChangeCompetitorState,
    SvgIcon,
    'custom-dropdown': CustomDropdown,
  },
  emits: ['locale-changed', 'competitor-changed', 'live-app-clicked'],
  setup(props, {emit}) {
    let closeLocalesList = ref(0);
    let currentLocale = ref({
      title: '',
      code: '',
    });

    let currCompetitorType = ref('');
    let userAppsList = ref({});
    currCompetitorType.value = props?.competitorType;
    const store = useStore();
    const currentApp = computed(() => store.getters.currentApp);

    function setAsoScoreColor(score) {
      if (score >= 0 && score < 31) {
        return 'red';
      } else if (score >= 31 && score < 41) {
        return 'orange';
      } else if (score >= 41 && score < 61) {
        return 'yellow';
      } else if (score >= 61 && score < 81) {
        return 'light-green';
      } else if (score >= 81) {
        return 'green';
      } else {
        return '';
      }
    }

    async function addApp() {
      emit('live-app-clicked', {application_id: props?.data?.app?.id});
    }

    function localeItemClicked(item) {
      closeLocalesList.value++;
      currentLocale.value = {...item, title: item.value};
      emit('locale-changed', currentLocale.value);
    }

    function competitorChanged(type, id, competitorType) {
      emit('competitor-changed', {type, id, competitorType});
      currCompetitorType.value = type === competitorType ? null : type;
    }

    watch(() => props?.competitorType, function (newValues, oldValues) {
      currCompetitorType.value = newValues;
    });

    return {
      setAsoScoreColor,
      localeItemClicked,
      cropImage,
      sanitizeString,
      competitorChanged,
      closeLocalesList,
      currentLocale,
      currCompetitorType,
      userAppsList,
      currentApp,
      addApp,
    }
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>