<script>
import AppRankTool from "@/components/DataTables/Tools/AppRankTool/index.vue";
import SapTool from "@/components/DataTables/Tools/SapTool/index.vue";

export default {
  name: "TopBlock",
  components: {SapTool, AppRankTool},
  props: {
    rows: {

    },
    store: {

    },
    keyword: {

    },
  },
  emits: ['sap-modal-trigger-clicked', 'add-tracking'],
  methods: {
    openSapModal(country) {
      this.$emit('sap-modal-trigger-clicked', {
        country: country,
      });
    },
    handleAddTracking(row) {
      this.$emit('add-tracking', {
        country: row.country,
        row: row,
      });
    },
  }
}
</script>

<template>
  <div class="top_block">
    <div class="title common-block-title">
      <slot name="title"></slot>
    </div>
    <div class="content">
      <div class="no-data-block" v-if="!rows.length">
        <div class="content">
          <span class="emoji">😢</span>
          <span class="text">No data found on your criteria.</span>
        </div>
      </div>
      <table cellspacing="0" cellpadding="0" v-else>
        <thead>
        <th class="common-heading-title">Country</th>
        <th class="common-heading-title text-right">App Rank</th>
        <th class="common-heading-title text-right">Popularity</th>
        <th></th>
        </thead>
        <tbody>
        <tr v-for="row in rows">
          <td class="country-col">
            <dynamic-image classes="flag"
                           :width="18"
                           :height="14"
                           :size="32"
                           :country-code="row.country.code"/>
            <span>{{row.country.name}}</span>
          </td>
          <td class="rank-col">
            <div>
              <app-rank-tool :item="row.last_rank_info"/>
            </div>
          </td>
          <td class="sap-col">
            <div>
              <sap-tool :keyword="this.keyword"
                        :sap="row.sap.value"
                        :store-key="store.key"
                        @sap-modal-trigger-clicked="openSapModal(row.country)"
              />
            </div>
          </td>
          <td>
            <custom-tooltip :classes="`table-action-button add-word ${row.tracking.is_tracked ? 'active' : ''}`"
                            v-on="row.tracking.is_tracked ? {} : {click: () => handleAddTracking(row)}">
              <template v-slot:slotTrigger>
                <svg-icon v-if="row.tracking.is_tracked"
                          icon="check-alt"
                          class="action-icon action-add-icon"/>
                <svg-icon v-else
                          icon="plus-alt"
                          class="action-icon action-add-icon"/>
              </template>
              <template v-slot:slotContent>
                <template v-if="row.tracking.is_tracked">
                  Added to Tracking Keywords
                </template>
                <template v-else>
                  Add to Tracking Keywords
                </template>
              </template>
            </custom-tooltip>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped lang="scss">
.top_block {
  width: 50%;
  padding: 20px;
  padding-top: 5px;

  display: flex;
  flex-direction: column;

  .content {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title {
    margin-bottom: 15px;
  }

  table {
    width: 100%;
    border: 1px solid var(--neutral-400);
    border-radius: 8px;

    th:first-of-type {
      text-align: left;
    }

    th,td {
      padding: 5px 20px;
      border-bottom: 1px solid var(--neutral-400);
    }

    tr:last-of-type td {
      border-bottom: none;
    }

    .sap-col > div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .rank-col > div {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      text-align: right;
    }

    .country-col {
      span {
        margin-left: 10px;
        color: var(--neutral-800);
      }

      img {
        user-select: none;
      }
    }
  }
}
</style>