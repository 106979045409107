<template>
  <div class="table-wrap">
    <div v-if="items?.length > 0" class="custom-competitors-data-table">
      <table>
        <thead>
        <tr>
          <th v-for="(headerItem, index) in headers"
              :key="index"
              :class="[headerItem.align ? [headerItem.align] : 'left', sortingMeta[sortingKeys.sorting] === headerItem.value ? 'active' : '']"
              :style="[{ width: headerItem.width ? headerItem.width : '', minWidth: headerItem.minWidth ? headerItem.minWidth : ''}]">
            <div class="inner-content">
              <div class="inner-content-wrap"
                   @click="sortClickHandler(headerItem.sortBy, headerItem.sortable)">
                <template v-if="headerItem.sortable">
                  <div class="sort-icons-block">
                    <svg-icon icon="arrow-solid-up"
                              class="icon icon-first"
                              :class="{active: sortingMeta[sortingKeys.sorting] === headerItem.value && sortingMeta[sortingKeys.direction] === 'asc'}"/>
                    <svg-icon icon="arrow-solid-down"
                              class="icon"
                              :class="{active: sortingMeta[sortingKeys.sorting] === headerItem.value && sortingMeta[sortingKeys.direction] === 'desc'}"/>
                  </div>
                </template>

                <span>
                      <template v-if="headerItem.tooltipText">
                  <custom-tooltip :width="headerItem.tooltipWidth"
                                  :padding-classes="'padding: 4px 0;'">
                    <template v-slot:slotTrigger>
                      {{ isMobile && headerItem.mobileText ? headerItem.mobileText : headerItem.text }}
                    </template>
                    <template v-slot:slotContent>
                      <span v-html="headerItem.tooltipText"></span>
                    </template>
                  </custom-tooltip>
                </template>
                      <template v-else-if="headerItem.dynamicTitle">
                        <div class="dynamic-title">
                          <slot name="header-title"></slot>
                        </div>
                      </template>
                <template v-else> {{
                    isMobile && headerItem.mobileText ? headerItem.mobileText : headerItem.text
                  }}</template>
                </span>
              </div>
            </div>
          </th>
        </tr>
        </thead>

        <tbody>
        <tr v-for="(item, index) in items"
            :key="index">
          <template v-if="items?.length < 5">
            <slot name="items" :item="item">
              items list
            </slot>
          </template>
          <template v-else>
            <template v-if="!showMoreActive || (index < 5 && showMoreActive)">
              <slot name="items" :item="item">
                items list
              </slot>
            </template>
            <template v-else-if="index === items?.length - 1">
              <td class="show-more"
                  :colspan="showMoreColspan">
                <button type="button"
                        class="show-more-btn"
                        @click="showMoreClickHandler">Show more
                </button>
              </td>
            </template>
          </template>
        </tr>
        <tr v-if="!showMoreActive && items?.length > 5">
          <td class="show-more"
              :colspan="showMoreColspan">
            <button type="button"
                    class="show-more-btn"
                    @click="showMoreClickHandler">Show less
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import CustomDataTableSelect from "@/components/Forms/CustomDataTableSelect";
import {mapGetters} from "vuex";

export default {
  name: "CustomDataTable",
  components: {
    'custom-data-table-select': CustomDataTableSelect,
  },
  emits: ['update-sort', 'show-more-clicked'],
  props: {
    fixedWidth: {
      type: Boolean,
      default: false
    },
    headers: {
      type: Array
    },
    items: {
      type: Array
    },
    itemsPerPage: {
      type: Number
    },
    rowsPerPageItems: {
      type: Array
    },
    pagination: {
      type: Object
    },
    sortingMeta: {
      type: Object
    },
    sortable: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    checkedTableItemsCount: {
      type: Number,
      required: false,
    },
    hasSelectionArray: {
      type: Array,
      default: () => []
    },
    sortingKeys: {
      type: Object,
      default: () => {
        return {
          direction: 'direction',
          sorting: 'sort_by'
        }
      }
    },
    showMoreOutsideTrigger: {
      type: Boolean,
      required: false,
    },
    resetToDefault: {
      type: Number,
      default: 0,
    },
    showMoreColspan: {
      type: Number,
      default: 3
    },
    showMoreCount: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      deleteAllCheckboxValue: false,
      showMoreActive: true,
      hashCode: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 10),
    }
  },
  methods: {
    selectClicked(e) {
      this.$emit('update-sort', {
        per_page: e.value,
      });
    },
    sortClickHandler(order_by, sortable) {
      if (!sortable) {
        return;
      }
      let direction;
      if (order_by !== this.sortingMeta[this.sortingKeys.sorting]) {
        direction = 'desc';
      } else {
        direction = this.sortingMeta[this.sortingKeys.direction] === 'desc' ? 'asc' : 'desc';
      }

      const tempKeysObject = {
        [this.sortingKeys.direction]: direction,
        [this.sortingKeys.sorting]: order_by,
      }
      this.$emit('update-sort', tempKeysObject);
    },
    showMoreClickHandler() {
      this.showMoreActive = !this.showMoreActive;
      this.$emit('show-more-clicked', {hash: this.hashCode, expanded: !this.showMoreActive});
    },
  },
  computed: {
    ...mapGetters([
      'isMobile',
    ]),
  },
  watch: {
    checkedTableItemsCount(newVal, oldVal) {
      if (newVal < oldVal) {
        this.deleteAllCheckboxValue = false;
      }
    },
    showMoreOutsideTrigger(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.showMoreActive = oldVal;
      }
    },
    resetToDefault(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.showMoreActive = true;
      }
    }
  }
}
</script>

<style src="./styles.scss" lang="scss"></style>