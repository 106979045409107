<template>
  <div class="app-screenshots-modal"
       :class="classes">
    <div class="screenshots-block">
      <template v-if="isScreenshotsDataLoaded">
        <custom-carousel
          v-if="screenshotsProcessedArray?.length > 0"
          :slides="screenshotsProcessedArray"
          :breakpoints="breakpoints"
          @onClickSlide="showSingle"
          :withNavigation="true"
        />
        <div v-else
             class="empty-screenshots">
          <div class="text">😔 No screenshots yet</div>
        </div>

        <div>

          <vue-easy-lightbox
              :visible="visibleRef"
              :imgs="imgsRef"
              :index="indexRef"
              teleport="body"
              @hide="onHide"
          ></vue-easy-lightbox>
        </div>

      </template>
      <template v-else>
        <slot>loader</slot>
      </template>
    </div>
  </div>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox';
import {ref, computed, watch} from 'vue';
import CustomCarousel from "@/components/UI/CustomCarousel";

export default {
  name: "AppScreenshots",
  props: {
    metricsData: {
      type: Object,
    },
    screenshotsData: {
      type: Array,
    },
    isMetricsDataLoaded: {
      type: Boolean,
      default: false
    },
    isScreenshotsDataLoaded: {
      type: Boolean,
      default: false,
    },
    currentCompetitorStore: {
      type: String,
    },
    breakpointsProps: {
      type: Object,
    },
    classes: {
      type: String,
    }
  },
  components: {
    VueEasyLightbox,
    CustomCarousel
  },
  setup(props) {
    let breakpoints = ref({});
    const visibleRef = ref(false);
    const indexRef = ref(0);
    const imgsRef = ref([]);
    const screensLoaded = computed(() => props.isScreenshotsDataLoaded);

    let screenshotsProcessedArray = ref([]);

    function setScreenshots() {
      let imageDirection = 'vertical';

      screenshotsProcessedArray.value = props?.screenshotsData?.map(item => {
        let formatImageString;
        let croppedScreenUrl;

        if (props.currentCompetitorStore === 'APP_STORE') {
          let splittedScreenUrl = item?.url?.split('/');
          let poppedItem = splittedScreenUrl.pop();
          let croppedScreen = splittedScreenUrl.join('/');
          let imageFormat = poppedItem.split('.')[1];
          let imageSizes = poppedItem.split('.')[0];
          let imageSizesSplitted = imageSizes.split('x');
          let imageWidth = parseInt(imageSizesSplitted[0]);
          let imageHeight = parseInt(imageSizesSplitted[1]);

          if (imageWidth > imageHeight) {
            imageDirection = 'horizontal';
            formatImageString = '460x244bb.' + imageFormat;
          } else {
            imageDirection = 'vertical';
            formatImageString = '244x460bb.' + imageFormat;
          }

          croppedScreen += '/' + formatImageString;
          croppedScreenUrl = croppedScreen;
        } else {
          const splittedScreenUrl = item?.url?.split('=');
          const splittedSize = splittedScreenUrl[1] ? splittedScreenUrl[1]?.split('-') : null;
          if (splittedSize === null || splittedSize?.length < 2) {

            return {
              ...item,
              croppedSrc: item?.url,
              direction: 'vertical',
            };

          }
          const width = splittedSize[0];
          const height = splittedSize[1];

          if (width > height) {
            imageDirection = 'horizontal';
            formatImageString = 'w460-h244';
          } else {
            imageDirection = 'vertical';
            formatImageString = 'w244-h460';
          }

          croppedScreenUrl = `${splittedScreenUrl[0]}=${formatImageString}`;
        }

        return {
          ...item,
          croppedSrc: croppedScreenUrl,
          direction: imageDirection,
        };
      });

      if (imageDirection === 'vertical') {
        if (props.breakpointsProps?.vertical) {
          breakpoints.value = {...props.breakpointsProps?.vertical};
        } else {
          breakpoints.value = {
            320: {
              itemsToShow: 2,
              snapAlign: 'start',
            },
            767: {
              itemsToShow: 3,
              snapAlign: 'start',
            },
            1024: {
              itemsToShow: 4,
              snapAlign: 'start',
            },
          };
        }
      } else {
        if (props.breakpointsProps?.horizontal) {
          breakpoints.value = {...props.breakpointsProps?.horizontal};
        } else {
          breakpoints.value = {
            320: {
              itemsToShow: 1,
              snapAlign: 'start',
            },
            767: {
              itemsToShow: 2,
              snapAlign: 'start',
            },
            1024: {
              itemsToShow: 2,
              snapAlign: 'start',
            },
          };
        }
      }
    }

    const onShow = () => {
      visibleRef.value = true;
    }
    const showSingle = (carouselItem) => {
      imgsRef.value = screenshotsProcessedArray?.value?.map(item => {
        return {
          src: item?.url
        }
      });

      const currItemIndex = screenshotsProcessedArray?.value?.findIndex(item => item?.url === carouselItem?.url);

      indexRef.value = currItemIndex;

      onShow();
    }
    const onHide = () => (visibleRef.value = false)

    watch([screensLoaded], async function (newValues, oldValues) {
      setScreenshots();
    });

    return {
      visibleRef,
      indexRef,
      imgsRef,
      showSingle,
      onHide,
      breakpoints,
      screenshotsProcessedArray,
    }
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>
