<template>
  <div class="chart-content-block"
       v-if="JSON.stringify(chartData) !== '{}'">
    <vue-highcharts
      type="chart"
      :options="preparedChartData"
      :redrawOnUpdate="true"
      :oneToOneUpdate="false"
      :animateOnUpdate="true"/>
  </div>
  <div v-else
       class="no-data-block">
    <div class="content">
      <span class="emoji">😢</span>
      <span class="text">No data found.</span>
    </div>
  </div>
</template>

<script>
import {formatDate} from "@/utils/utils";

export default {
  name: 'InstallsChart',
  props: {
    chartData: Object,
    showRevenue: Boolean,
  },
  computed: {
    preparedChartData() {
      let dataArray = [];
      for (const key in this.chartData) {
        dataArray.push({
          date: formatDate(key, 'month-day-year'),
          rankData: this.chartData[key]
        });
      }

      let dates = [];
      let rank = [];
      for (const key in this.chartData) {
        dates.push(formatDate(key, 'month-day-year'));
        rank.push(this.chartData[key]);
      }

      const rankingData = dataArray.sort(function (a, b) {
        const dateA = new Date(a.date).getTime();
        const dateB = new Date(b.date).getTime();
        return dateA < dateB ? -1 : 1;
      });

      function shortenDigits(val) {
        let newVal = val;
        if (typeof val !== "number") {
          newVal = Number(val);
        }
        if (newVal >= 1e3 || newVal <= -1e3) {
          const units = ["k", "M", "B", "T"];
          let unit = Math.floor(((newVal).toFixed(0).length - 1) / 3) * 3
          let num = (newVal / ('1e' + unit)).toFixed(1)
          let unitname = units[Math.floor(unit / 3) - 1]
          return Math.abs(num) + unitname
        }

        return Math.abs(newVal);
      }

      let GP_series = {
        name: 'Revenue',
        data: rankingData.map(item => item.rankData.revenue),
        lineWidth: 2,
        pointHitRadius: 10,
        color: '#59cbee',
        marker: {
          radius: 1,
          shape: "circle",
          symbol: "circle",
          fillColor: '#FFFFFF',
          lineWidth: 1,
          lineColor: null,
          states: {
            hover: {
              fillColor: 'white',
              radius: 5,
              lineWidth: 2,
              lineColor: '#59cbee',
              animation: {
                duration: 500 // transition animation duration
              }
            }
          }
        },
        states: {
          inactive: {
            opacity: 1
          },
          hover: {
            halo: {
              size: 4,
              attributes: {
                fill: 'transparent',
                'stroke-width': 2,
                stroke: 'transparent'
              }
            },
            lineWidth: 2,
          }
        },
        pointPlacement: 'on',
        dataLabels: {
          enabled: false
        },
        animation: false
      };
      let series = [{
        name: 'Installs',
        data: rankingData.map(item => item.rankData.installs),
        lineWidth: 2,
        pointHitRadius: 10,
        color: '#5d71fa',
        marker: {
          radius: 1,
          shape: "circle",
          symbol: "circle",
          fillColor: '#FFFFFF',
          lineWidth: 1,
          lineColor: null,
          states: {
            hover: {
              fillColor: 'white',
              radius: 5,
              lineWidth: 2,
              lineColor: '#5d71fa',
              animation: {
                duration: 500 // transition animation duration
              }
            }
          }
        },
        states: {
          inactive: {
            opacity: 1
          },
          hover: {
            halo: {
              size: 4,
              attributes: {
                fill: 'transparent',
                'stroke-width': 2,
                stroke: 'transparent'
              }
            },
            lineWidth: 2,
          }
        },
        pointPlacement: 'on',
        dataLabels: {
          enabled: false
        },
        animation: false
      }];

      if (this.showRevenue) {
        series.push(GP_series);
      }

      return {
        chart: {
          type: 'spline',
          height: 444,
          // width: '100%',
        },
        title: false,
        xAxis: {
          categories: rankingData.map(item => item.date),
          title: false,
          labels: {
            style: {
              color: '#4d5874',
              fontSize: '15px',
              textOverflow: 'none',
              whiteSpace: 'nowrap',
            },
            formatter: function () {
              if (rankingData?.length === 12) {
                // if (!this.isFirst && !this.isLast) {
                return formatDate(this.value, 'month');
                // }
              } else if (rankingData?.length > 4 && rankingData?.length < 12) {
                return formatDate(this.value, 'month');
              } else {
                return formatDate(this.value, 'month');
              }
            },
          },
          gridLineDashStyle: 'solid',
          gridLineColor: '#e5e8eb',
          gridLineWidth: 1,
          showLine: true,
          lineWidth: 1,
          lineColor: '#e5e8eb',
          offset: 12,
        },
        yAxis: {
          title: false,
          labels: {
            style: {
              color: '#4d5874',
              fontSize: 15
            },
            formatter: function () {
              return shortenDigits(this.value);
            },
          },
          gridLineDashStyle: 'solid',
          gridLineColor: '#e5e8eb',
          gridLineWidth: 1, // width of the grid line
          showLine: true,
          lineWidth: 1,
          lineColor: '#e5e8eb',
        },
        series,
        options: {
          scales: {
            xAxes: [{
              gridLines: {
                display: false,
              },
            }],
            yAxes: [{
              stacked: true,
              gridLines: {
                borderDash: [8, 4],
                color: "#f0f3fd",
                zeroLineColor: 'transparent',
                drawBorder: false,
                drawOnChartArea: true,
              },
              type: 'linear',
            }]
          },
        },
        tooltip: {
          width: 280,
          distance: 20,
          padding: 0,
          outside: false,
          useHTML: true,
          backgroundColor: '#fff',
          borderWidth: 0,
          borderRadius: 4,
          shadow: false,
          shape: "square",
          hideDelay: 15,
          shared: true,
          split: false,
          enabled: true,
          formatter: function () {
            const pointDate = formatDate(this?.x, 'month-year');
            let shortenedClass = this.points?.length === 1 ? 'shortened' : '';
            let toolTipRow = ``;
            let colorClass = '';

            for (const key in this.points) {
              const name = this.points[key]?.series?.name;
              let value = this.points[key]?.y?.toLocaleString('ru-RU', {maximumFractionDigits: 0});
              if (name === 'Installs') {
                colorClass = 'blue';
              } else {
                colorClass = 'light-blue';
              }
              if (name === 'Revenue') {
                value = '$ ' + value;
              }

              toolTipRow += `
              <div class="tooltip-row">
                <div class="tooltip-item-title ${colorClass}">${name}</div>
                <div class="tooltip-item-value">${value}</div>
            </div>
              `
            }

            // let revenueBlocks = `
            // <div class="tooltip-row">
            //     <div class="tooltip-item-title light-blue">Revenue</div>
            //     <div class="tooltip-item-value">${revenueValue}</div>
            // </div>
            // `;

            return `<div class="tooltip-inner-container ${shortenedClass}">
                      <div class="tooltip-heading">${pointDate}</div>
                      <div class="tooltip-content">
                   ${toolTipRow}
                      </div>
                    </div>`
          }
        },
        legend: {
          enabled: true,
          align: 'left',
          verticalAlign: 'top',
          itemStyle: {
            color: '#46516d',
            fontWeight: 'normal',
            fontSize: '16px',
          },
          // labelFormatter: function() {
          //   return '<span style="color: #46516d; font-size: 18px;line-height: 1.5;font-weight: normal;">' + this.name + '</span>';
          // },
          margin: 24,
        },
      };
    },
  },
}
</script>
