<template>
  <div class="metadata-table-block">

    <template v-if="!isMobile">
      <table class="metadata-table"
             :class="{'body-border-top': withHeader}">
        <thead v-if="withHeader">
        <tr>
          <th class="first-col">Title</th>
          <th>
            <template v-if="appStore === 'APP_STORE'">
              Subtitle
            </template>
            <template v-else>
              Short Description
            </template>
          </th>
          <th class="last-col">Localizations</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="first-col">
            <div class="inner-content">
              {{ data.title }}
            </div>
          </td>
          <td>
            <div class="inner-content">
              {{ data.subTitle }}
            </div>
          </td>
          <td class="last-col">
            <div class="inner-content with-trigger"
                 :class="{opened: showDescriptionBlock}">
              <div class="locales-block">
                <span class="locales-amount">({{ data.locales?.length }})</span>
                {{ data.localesString }}
                <div class="trigger"
                     :class="{active: showDescriptionBlock}"
                     @click="triggerDescription">
                  <div class="trigger-text">
                    Description
                  </div>
                  <div class="icon">
                    <svg-icon icon="angle-down-solid"/>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="description-block"
           v-if="showDescriptionBlock">
        <div v-for="item in data.descriptionArray"
             class="locales-bundle">

          <div class="description-locales">
            <span class="locales-amount">({{ item.localesAmount }})</span>
            {{ item.localesString }}
          </div>
          <div class="description-text"
               style="white-space: pre-wrap">
            <span v-html="item.description"></span>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="mobile-metadata-block">
        <div class="metadata-header">
          <div class="app-logo">
            <img :src="logo"
                 alt="App logo"
                 width="24"
                 height="24">
          </div>
          <div class="metadata-title">{{ data.title }}</div>
        </div>
        <div class="metadata-subtitle"
             v-if="data.subTitle">{{ data.subTitle }}
        </div>
        <div class="metadata-description">
          <div class="inner-content with-trigger">
            <div class="locales-block">
              <span class="locales-amount">({{ data.locales?.length }})</span>
              {{ data.localesString }}
            </div>
            <div class="trigger"
                 :class="{active: showDescriptionBlock}"
                 @click="triggerDescription">
              <div class="trigger-text">
                Show Description
              </div>
              <div class="icon">
                <svg-icon icon="angle-down-solid"/>
              </div>
            </div>
          </div>
          <div class="description-block"
               v-if="showDescriptionBlock">
            <div v-for="item in data.descriptionArray"
                 class="locales-bundle">

              <div class="description-locales">
                <span class="locales-amount">({{ item.localesAmount }})</span>
                {{ item.localesString }}
              </div>
              <div class="description-text"
                   style="white-space: pre-wrap">
                <span v-html="item.description"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {ref, computed, onMounted} from 'vue';
import {useStore} from 'vuex';

export default {
  name: "ModalAppMetadata",
  props: {
    data: {
      type: Object,
    },
    withHeader: {
      type: Boolean,
      default: false,
    },
    appLogo: {
      type: String
    },
    appStore: {
      type: String,
      default: 'APP_STORE',
    },
  },
  setup(props) {
    let showDescriptionBlock = ref(false);
    const store = useStore();
    let logo = ref('');
    const isMobile = computed(() => store.getters.isMobile);

    function triggerDescription() {
      showDescriptionBlock.value = !showDescriptionBlock.value;
    }

    onMounted(async function () {
      logo.value = props.appLogo;
    });

    return {
      showDescriptionBlock,
      triggerDescription,
      isMobile,
      logo,
    }
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>