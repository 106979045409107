<template>
  <div class="information-block">
    <div class="additional-information">
      <template v-if="isInfoDataLoaded">
        <div class="block-heading">
          <div class="title">Additional Information</div>
          <div class="deleted-app-text"
               v-if="appInfoData?.deleted_at">😢
            <span>Deleted from App Store</span>
          </div>
        </div>
        <div class="information-table">
          <table>
            <tbody>
            <tr>
              <td class="first-col">Rating:</td>
              <td>
                <div class="rating">
                  <div class="css-icons star-icon"></div>
                  {{ appInfoData?.rating }}
                </div>
              </td>
            </tr>
            <tr>
              <td class="first-col">Website:</td>
              <td>
                <template v-if="!appInfoData?.site">-</template>
                <template v-else>
                  <div class="inner-content">
                    <a :href="appInfoData.site">{{ appInfoData.site }}</a>
                  </div>
                </template>
              </td>
            </tr>
            <tr>
              <td class="first-col">Email:</td>
              <td>
                <div class="inner-content">
                  {{ appInfoData?.email }}
                </div>
              </td>
            </tr>
            <tr>
              <td class="first-col">Privacy Policy:</td>
              <td>
                <template v-if="!appInfoData?.policy">-</template>
                <template v-else>
                  <div class="inner-content">
                    <a :href="appInfoData.site">{{ appInfoData.policy }}</a>
                  </div>
                </template>
              </td>
            </tr>
            <tr>
              <td class="first-col">Categories:</td>
              <td>
                <div class="inner-content">
                  <template v-for="(category, idx) in appInfoData?.categories">
                    <template v-if="idx !== 0">,&nbsp;</template>
                    <span class="category-item">{{ category?.title }}</span>
                  </template>
                </div>
              </td>
            </tr>
            <tr>
              <td class="first-col">Update Date:</td>
              <td>
                {{ formatItemDate(appInfoData?.update_date) }}
              </td>
            </tr>
            <tr>
              <td class="first-col">Size:</td>
              <td>
                <template v-if="!appInfoData?.size_mb || +appInfoData?.size_mb === 0">-</template>
                <template v-else>{{ formatLocaleString(appInfoData?.size_mb) }}MB</template>
              </td>
            </tr>
            <tr>
              <td class="first-col">IAP:</td>
              <td>
                <template v-if="appInfoData?.IAP?.from && appInfoData?.IAP?.to">
                  <span>${{ appInfoData?.IAP?.from }}</span> - <span>${{ appInfoData?.IAP?.to }}</span>
                </template>
                <template v-else>-</template>
              </td>
            </tr>
            <tr v-if="appStore !== 'APP_STORE'">
              <td class="first-col">Installs:</td>
              <td>
                {{ formatLocaleString(appInfoData?.installs) }}
              </td>
            </tr>
            <tr>
              <td class="first-col">Release Date:</td>
              <td>
                {{ formatItemDate(appInfoData?.release_date) }}
                <template v-if="appInfoData?.age_days">
                  (App age: {{ convertItemDays(appInfoData.age_days) }})
                </template>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </template>
      <template v-else>
        <preloader-table-small loader-size="50px"></preloader-table-small>
      </template>
    </div>
    <div class="version-history">
      <template v-if="isHistoryDataLoaded">
        <div class="block-heading">
          <div class="title">Version history</div>
        </div>
        <div class="version-history-block-wrap">
          <div class="version-history-block">
            <template v-if="historyData?.length > 0">
              <div class="version-history-item"
                   v-for="item in historyData">
                <div class="history-heading">
                  <div class="version">
                    {{ item?.version }}
                  </div>
                  <div class="date">
                    {{ item?.date }}
                  </div>
                </div>
                <div class="history-release-notes"
                     v-html="item?.note">
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
      <template v-else>
        <preloader-table-small loader-size="50px"></preloader-table-small>
      </template>
    </div>
  </div>
</template>

<script>
import {formatDate, convertDays} from "@/utils/utils";
import PreloaderTableSmall from "@/components/UI/PreloaderTableSmall/index.vue";
import {httpRequest} from "@/api";
import {mapGetters} from "vuex";

export default {
  name: "ModalAppBottomDetails",
  components: {
    'preloader-table-small': PreloaderTableSmall,
  },
  computed: {
    ...mapGetters([
      'promiseSettledData',
      'currentCountry',
    ]),
  },
  props: {
    appData: {
      type: Object,
    },
    appStore: {
      type: String,
    },
  },
  data() {
    return {
      isInfoDataLoaded: false,
      isHistoryDataLoaded: false,
      appInfoData: {},
      historyData: {},
    }
  },
  async mounted() {
    this.fetchAppInfoData();
    this.fetchVersionHistoryData();
  },
  methods:{
    async fetchAppInfoData() {
      const url = '?country_code=' + this.currentCountry?.code + '&app_id=' + this.appData?.id;
      this.isInfoDataLoaded = false;
      const appInfoData = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.competirorsFinder.APP_INFO_BLOCK + url);

      if (JSON.stringify(appInfoData) !== '{}') {
        this.appInfoData = {
          ...appInfoData,
          store: this.appData?.store,
        };
      }

      this.isInfoDataLoaded = true;
    },
    async fetchVersionHistoryData() {
      const url = '?country_code=' + this.currentCountry?.code + '&app_id=' + this.appData?.id;
      this.isHistoryDataLoaded = false;
      const appVersionHistoryData = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.competirorsFinder.VERSION_HISTORY_BLOCK + url);

      if (appVersionHistoryData?.length > 0) {
        this.historyData = [...appVersionHistoryData];
      }

      this.isHistoryDataLoaded = true;
    },
  },
  setup() {
    function formatItemDate(val) {
      if (val) {
        return formatDate(val, 'full-months');
      }
      return '';
    }

    function convertItemDays(val) {
      return convertDays(val);
    }

    function formatLocaleString(value) {
      return value?.toLocaleString('ru-RU', {maximumFractionDigits: 0});
    }

    return {
      formatItemDate,
      convertItemDays,
      formatLocaleString,
    }
  },
  watch: {
    appData() {
      this.fetchAppInfoData();
      this.fetchVersionHistoryData();
    }
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>