<template>
  <div class="app-metrics-modal"
       :class="classes">
    <div class="metrics-grid-block">
      <template v-if="isMetricsDataLoaded">
        <div class="metrics-grid">
          <slot name="metrics-items"></slot>
        </div>
      </template>
      <template v-else>
        <slot name="preloader-block">loader</slot>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalAppMetrics",
  props: {
    isMetricsDataLoaded: {
      type: Boolean,
      default: false
    },
    classes: {
      type: String,
    }
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>