import {httpRequest} from "@/api";

export default {
    COUNTRIES: 'api/catalog/countries',

    getCountriesByStore(store_key) {
        let url = '?store_key=' + store_key;
        url += '&active_only=true';

        return new Promise((resolve, reject) => {
            httpRequest('GET', process.env.VUE_APP_API_URL + this.COUNTRIES + url).then(response => {
                resolve(response);
            }).catch(error => {
                console.error(error);
                reject(error);
            });
        });
    },

    getApp(appId, countryCode) {
        let url = '?app_id=' + appId;
        url += '&country_code=' + countryCode;

        return new Promise((resolve, reject) => {
            httpRequest('GET', process.env.VUE_APP_API_URL + this.GET_CURRENT_APP + url).then(response => {
                resolve(response);
            }).catch(error => {
                console.error(error);
                reject(error);
            });
        });
    }
};